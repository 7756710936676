<div class="update-browser-container" *ngIf="showTip">
  <div class="update-browser-tip">
      Your browser is {{browserName}}.
      We recommend you use Google Chrome or new Microsoft Edge for a better experience!
  </div>
  <button class="ml-4"
          nz-button
          nzType="primary"
          (click)="linkToChrome()">Chrome</button>
  <button class="ml-4"
          nz-button
          nzType="primary"
          (click)="linkToEdge()">Edge</button>
  <button class="ml-4"
          nz-button
          nzType="cancel"
          (click)="ignore()">Ignore</button>
</div>
