import { GuideStep } from './guide.store'

export class AddStep {
    static readonly type = '[Guide] add step'
    constructor(public payload: GuideStep) {}
}

export class FulfillStep {
    static readonly type = '[Guide] fulfill step'
    constructor() {}
}

export class NextGuide {
    static readonly type = '[Guide] next guide'
    constructor() {}
}

export class EndGuide {
    static readonly type = '[Guide] end guide'
    constructor() {}
}

