import { HttpParams } from '@angular/common/http'
import * as _ from 'lodash'
import { ALL_DEGREE, ALL_DIRECTION } from './core.constants'

const emergencyIcon = require('./images/emergency.png')
const emergencyOffIcon = require('./images/emergency-off.png')
const sosIcon = require('./images/sos.png')
import { sortBy } from 'lodash'
import { of } from 'rxjs'
import { take } from 'rxjs/operators'
import * as moment from 'moment'
import { isObject, isBoolean } from 'lodash'
import { GeoFence } from './states/geo-fence/geo-fence.model'
import { SimpleRegion } from './states/session/session.model'

// template compile
// template format `http://www.tracmanager.com/GetHelp.aspx?name=${nam…&company=${company}&phone=${phone}&email=${email}"]`
export function templateCompile(temp: string, value): string {
    return temp.replace(/#{(\w+)}/g, (matchStr) => {
        const prop = matchStr.substring(2, matchStr.length - 1)

        return value[prop] || ''
    })
}

export const LAT_LNG_KEY = 3600000

/**
 * covert a object map to HttpParams
 * @param {Object} obj
 * @return {HttpParams}
 */
export function toHttpParams(obj: object = {}): HttpParams {
    let params = new HttpParams()
    Object.keys(obj).forEach((key: string) => {
        if (Array.isArray(obj[key])) {
            obj[key].forEach((item) => {
                params = params.append(key, item)
            })
        } else if (obj[key] !== undefined) {
            params = params.set(key, obj[key])
        }
    })

    return params
}

export const ClustererOptions = [
    { scale: '200m', zoom: 15 },
    { scale: '500m', zoom: 14 },
    { scale: '1km', zoom: 13 },
    { scale: '2km', zoom: 11 },
    { scale: '5km', zoom: 10 },
    { scale: '10km', zoom: 9 }
]

export const AssetModelType = {
    zj300: 'ZJ300',
    obd: 'OBD.SINO',
    camera: 'CAMERA',
    camera_ai: 'CAMERA.AI'
}

export const Notifications = ['Both', 'Off', 'Arrive', 'Leave']

export const Schedules = ['Always on', 'One time', 'Daily', 'Weekly']

export const Weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const TimePartial = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']

export const dateType = ['Days', 'Weeks', 'Months', 'Years']

export const AlertTypes = [
    { id: '-1', name: 'All' },
    { id: '2', name: 'Battery low' },
    { id: '10', name: 'Battery full' },
    { id: '7', name: 'Entering geofence' },
    { id: '8', name: 'Exiting geofence' },
    { id: '3', name: 'Disturbance' },
    { id: '4', name: 'Movement' },
    { id: '5', name: 'SOS' },
    { id: '11', name: 'Speeding' },
    { id: '12', name: 'Stopped' },
    { id: '13', name: 'Arriving POI' },
    { id: '14', name: 'Leaving POI' },
    { id: '6', name: 'Communication interrupted' },
    { id: '16', name: 'Tracker lost power connection' },
    { id: '17', name: 'Maintenance' },
    { id: '21', name: 'Emergency mode' }
]

export const USAStates = [
    'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE',
    'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY',
    'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT',
    'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK',
    'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA',
    'VI', 'VT', 'WA', 'WI', 'WV', 'WY', 'AB', 'BC', 'MB', 'NB',
    'NL', 'NS', 'NT', 'ON', 'PE', 'QC', 'SK', 'YT', 'AG', 'BN',
    'BS', 'CH', 'CI', 'CL', 'CP', 'CS', 'DF', 'DG', 'GE', 'GJ',
    'HD', 'JA', 'MC', 'MR', 'MX', 'NA', 'NL', 'OA', 'PU', 'QE',
    'QI', 'SI', 'SL', 'SO', 'TA', 'TB', 'TL', 'VC', 'YU', 'ZA',
    'OT', 'NF', 'NU'
]

export const TimeSelectMap = {
    all: 'All',
    lastHour: 'Last hour',
    today: 'Today',
    yesterday: 'Yesterday',
    previousDays: 'Previous days',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    thisMonth: 'This month',
    lastMonth: 'Last month',
    previousMonths: 'Previous months',
    thisYear: 'This year',
    lastYear: 'Last year',
    previousYears: 'Previous Years',
    otherTime: 'Other time'
}

export const permissionsDict = {
    profile_enable: 'PROFILE_EDIT', // shared账号没有权限
    show_idle_time: 'SHOW_IDLE_TIME',
    show_maintenance: 'SHOW_MAINTENANCE',
    show_power_on: 'SHOW_POWER_ON',
    show_slow_speed: 'SHOW_SLOW_SPEED',
    show_speed_violation: 'SHOW_SPEED_VIOLATION',
    show_vehicle: 'SHOW_VEHICLE',
    show_workingtime: 'SHOW_WORKINGTIME',
    show_elog: 'SHOW_ELOG',
    show_state_mileage: 'SHOW_STATE_MILEAGE',
    show_hos_driving: 'SHOW_HOS_DRIVING',
    show_hos_idle: 'SHOW_HOS_IDLE',
    show_dangerous_driving: 'SHOW_DANGEROUS_DRIVING',
    show_over_local_speed: 'SHOW_OVER_LOCAL_SPEED',
    show_immobilizer: 'SHOW_IMMOBILIZER',
    show_bus: 'SHOW_BUS'
}

export const rolePermissions = {
    show_driver_management: 'SHOW_DRIVER_MANAGEMENT',
    show_access_control: 'SHOW_ACCESS_CONTROL',
    show_driver_score: 'SHOW_DRIVER_SCORE',
    show_users_manager: 'SHOW_USERS_MANAGER'
}

export function tablePageIndexChange(pageInfo, pageIndex) {
    if (pageInfo.count > pageInfo.page_size && pageInfo.count % pageInfo.page_size === 1) {
        return pageIndex - 1
    }

    return pageIndex
}

export function timeSelectMap(input) {
    return TimeSelectMap[input] || input
}

export function resolveStatisticsType(time) {
    const param = time.toLowerCase()
    if (param.indexOf('day') >= 0 || time === 'otherTime') {
        return 3
    } else if (param.indexOf('month') >= 0) {
        return 2
    } else {
        return 1
    }
}

export function fuelUs2Metric(val: number): number {
    if (!val) {
        return 0
    }

    return 235.2 / val
}

export function fuelMetric2Us(val: number): number {
    if (!val) {
        return 0
    }

    return 235.22 / val
}

export function km2mile(val: number): number {
    return val * 0.621371192237
}

export function mile2km(val: number): number {
    return val / 0.621371192237
}

export function m2ft(val: number): number {
    return val / 0.3048
}

export function ft2m(val: number): number {
    return val * 0.3048
}

export function second2hour(val: number, type: string, precision: number = 2) {
    if (type === 'integer') {
        return `${Math.floor(Number(val) / 3600)}`
    }

    return `${(val / 3600).toFixed(precision)}`
}

export function hour2second(val) {
    return val * 3600
}

export const PATTEN = {
    phoneRegex: /^[\d\s()+-]+$/,
    snRegex: /^[a-zA-Z0-9]+$/,
    emailRegex: /\w[\-\.\w]+@\w[\-\.\w]*\.[a-zA-Z]+/,
    passwordRegex: /^[@A-Za-z0-9!#\$%\^&\*\.~]{6,20}$/,
    mapPosition: /^\s*-?(\d|[1-9]\d|1[0-7]\d|180)(\.\d*)?\s*,\s*-?(\d|[1-8]\d|90)(\.\d*)?\s*$/
}

export function isInString(dist, part) {
    return dist.toLowerCase().includes(part.toLowerCase())
}

export const ServiceTypeDIct = {
    1: 'day',
    2: 'week',
    3: 'month',
    4: 'year'
}

export function getElementScreenLeft(element) {
    const elemLeft = element.getBoundingClientRect().left
    const scrollLeft = document.documentElement.scrollLeft

    return elemLeft - scrollLeft
}

export function getElementScreenTop(element) {
    const elemTop = element.getBoundingClientRect().top
    const scrollTop = document.documentElement.scrollTop

    return elemTop - scrollTop
}

export function selectToVal(selectText) {
    const temp = { out_alert: 1, enter_alert: 1 }
    switch (selectText) {
        case 'Both':
            temp.out_alert = 1
            temp.enter_alert = 1
            break
        case 'Off':
            temp.out_alert = 0
            temp.enter_alert = 0
            break
        case 'Arrive':
            temp.out_alert = 0
            temp.enter_alert = 1
            break
        case 'Leave':
            temp.out_alert = 1
            temp.enter_alert = 0
            break
        default:
            console.warn('data format error')
    }

    return temp
}

export function valToSelect(val) {
    if (val.enter_alert && val.out_alert) {
        return 'Both'
    }
    if (val.enter_alert === 0 && val.out_alert === 0) {
        return 'Off'
    }
    if (val.enter_alert === 1) {
        return 'Arrive'
    }
    if (val.out_alert === 1) {
        return 'Leave'
    }
    console.error('data format error')
}

export function decodeLatLng(value) {
    return value / LAT_LNG_KEY
}

export function encodeLatLng(value) {
    return Math.round(value * LAT_LNG_KEY)
}

export function degreeToDirection(degree) {
    let result = '-'
    const degreeX10 = (parseFloat(degree) + 22.5) % 360
    if (degree !== undefined && degree !== null) {
        result = ALL_DIRECTION[0]
        /* 北纬337.5到22.5，加上22.5之后为360到45即为0-45 */
        if (degreeX10 === 22.5) {
            result = ALL_DIRECTION[0]
        } else {
            ALL_DIRECTION.forEach((val, i) => {
                if (ALL_DEGREE[i] <= degreeX10 && degreeX10 < ALL_DEGREE[i + 1]) {
                    result = ALL_DIRECTION[i]
                }
            })
        }
    }

    return result
}

export function sortTrackers(trackers) {
    return sortBy(trackers, (item) => item.alias)
}

export function busIconHelper(tracker, profile) {
    const imgUrl = `/assets/images/tracker-icon/13-sprites.png`
    let imgUrlPromise: Promise<string> = new Promise<string>(function (resolve) {
        resolve(imgUrl)
    })
    // default off line
    let directionIndex = 9
    let slowIcon = 0
    if (profile.slow_speed > 0 && profile.slow_speed > tracker.speed) {
        slowIcon = 1
    }
    // if (tracker.violationData && profile.speed_violation > 0) {
    //     slowIcon = 2
    // }
    if (tracker.login === 1 && tracker.is_moving === 1) {
        const direction = degreeToDirection(tracker.degree)
        directionIndex = ALL_DIRECTION.findIndex((item) => item === direction)
    }
    // stop
    if (tracker.login === 1 && tracker.is_moving === 0) {
        directionIndex = 8
    }

    // // idle
    // if (tracker.idle) {
    //     directionIndex = 10
    // }

    // 移动的tracker,设置不一样的锚点是因为移动的点和离线以及停止的点marker尺寸不一样
    let anchor = new google.maps.Point(26, 55)
    if (tracker.is_moving === 1 && tracker.login !== 0) {
        anchor = new google.maps.Point(26, 33)
    }

    const sx = 52 * directionIndex
    const sy = 60 * slowIcon
    if (tracker.emergencyAble) {
        const resolveEmergencyIcon = tracker.emergency.status > 0 ? emergencyIcon : emergencyOffIcon
        imgUrlPromise = emergencyTrackerIcon(resolveEmergencyIcon, imgUrl, anchor, sx, sy)
    }

    return {
        url: imgUrlPromise,
        size: new google.maps.Size(52, 60),
        origin: new google.maps.Point(52 * directionIndex, 60 * slowIcon),
        anchor
    }
}

export function trackerIconHelper(tracker, profile) {
    const imgUrl = `/assets/images/tracker-icon/${tracker.icon}-sprites.png`
    let imgUrlPromise: Promise<string> = new Promise<string>(function (resolve) {
        resolve(imgUrl)
    })
    // default off line
    let directionIndex = 9
    let slowIcon = 0
    if (profile.slow_speed > 0 && profile.slow_speed > tracker.speed) {
        slowIcon = 1
    }
    // if (tracker.violationData && profile.speed_violation > 0) {
    //     slowIcon = 2
    // }
    if (tracker.login === 1 && tracker.is_moving === 1) {
        const direction = degreeToDirection(tracker.degree)
        directionIndex = ALL_DIRECTION.findIndex((item) => item === direction)
    }
    // stop
    if (tracker.login === 1 && tracker.is_moving === 0) {
        directionIndex = 8
    }

    // // idle
    // if (tracker.idle) {
    //     directionIndex = 10
    // }

    // 移动的tracker,设置不一样的锚点是因为移动的点和离线以及停止的点marker尺寸不一样
    let anchor = new google.maps.Point(26, 55)
    if (tracker.is_moving === 1 && tracker.login !== 0) {
        anchor = new google.maps.Point(26, 33)
    }

    const sx = 52 * directionIndex
    const sy = 60 * slowIcon
    if (tracker.emergencyAble) {
        const resolveEmergencyIcon = tracker.emergency.status > 0 ? emergencyIcon : emergencyOffIcon
        imgUrlPromise = emergencyTrackerIcon(resolveEmergencyIcon, imgUrl, anchor, sx, sy)
    }

    return {
        url: imgUrlPromise,
        size: new google.maps.Size(52, 60),
        origin: new google.maps.Point(52 * directionIndex, 60 * slowIcon),
        anchor
    }
}

export function trackerSpeedIconHelper(tracker, profile) {
    const direction = degreeToDirection(tracker.degree)
    const directionIndex = ALL_DIRECTION.findIndex((item) => item === direction)
    const isSpeeding = tracker.open_over_speed && tracker.speed > tracker.speed_limit
    const originX = isSpeeding ? 0 : 1
    const imgUrlPromise = base64ToImg(sosIcon).then((sosImage) => {
        return new Promise<string>((resolve, reject) => {
            const img = new Image()
            img.src = '/assets/images/tracker-icon/speed.png'
            img.onload = function () {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                canvas.width = 80
                canvas.height = 80
                ctx.save()
                ctx.translate(40, 40)
                ctx.rotate((45 * directionIndex * Math.PI) / 180)
                ctx.drawImage(img, originX * 80, 0, 80, 80, -40, -40, 80, 80)
                ctx.restore()
                if (tracker.sos) {
                    ctx.drawImage(sosImage, 45, 16)
                }
                ctx.font = '10px Open Sans'
                ctx.textAlign = 'center'
                ctx.fillStyle = isSpeeding ? '#e35707' : '#2ac474'
                const speed = profile.units === 0 ? tracker.speed : km2mile(tracker.speed).toFixed(0)
                const unit = profile.units === 0 ? 'km/h' : 'mph'
                ctx.fillText(speed, 40, 38)
                ctx.fillText(unit, 40, 50)
                resolve(canvas.toDataURL())
            }
            img.onerror = function (e) {
                reject(e)
            }
        })
    })

    return {
        url: imgUrlPromise,
        anchor: new google.maps.Point(40, 40)
    }
}

function emergencyTrackerIcon(eImgUrl, imgUrl, anchor, sx, sy): Promise<string> {
    const img = new Image()

    function loadTrackerIcon(eImg): Promise<string> {
        return new Promise(function (resolve, reject) {
            img.src = imgUrl
            img.onload = function () {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                canvas.width = 52
                canvas.height = 60
                ctx.drawImage(img, sx, sy, 52, 60, 0, 0, 52, 60)
                ctx.drawImage(eImg, 16, 16)
                resolve(canvas.toDataURL())
            }
            img.onerror = function (e) {
                reject(e)
            }
        })
    }

    return base64ToImg(eImgUrl).then((eImage) => {
        return loadTrackerIcon(eImage)
    })
}

export function stopIconHelper(index) {
    const img = new Image()

    return new Promise(function (resolve, reject) {
        img.src = '/assets/images/marker-icons/stop.png'
        img.onload = function () {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = 35
            canvas.height = 40
            ctx.drawImage(img, 0, 0)
            ctx.font = '10px Open Sans'
            ctx.textAlign = 'center'
            ctx.fillStyle = '#373737'
            ctx.fillText(index, 17, 20)
            resolve(canvas.toDataURL())
        }
        img.onerror = function (e) {
            reject(e)
        }
    })
}

export function endIconHelper(index = 0) {
    return {
        url: `/assets/images/marker-icons/end.png`,
        size: new google.maps.Size(35, 40),
        origin: new google.maps.Point(35 * index, 0),
        anchor: new google.maps.Point(17, 40)
    }
}

export function startIconHelper(index = 0) {
    return {
        url: `/assets/images/marker-icons/start.png`,
        size: new google.maps.Size(35, 40),
        origin: new google.maps.Point(35 * index, 0),
        anchor: new google.maps.Point(17, 40)
    }
}

export function tripClickIconHelper() {
    return {
        url: '/assets/images/marker-icons/trip-click.png',
        size: new google.maps.Size(26, 26),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(13, 13)
    }
}

function base64ToImg(base64): Promise<HTMLImageElement> {
    const img = new Image()

    return new Promise(function (resolve, reject) {
        if (typeof base64 === 'string') {
            img.src = base64
        } else {
            img.src = base64.default
        }
        img.onload = function () {
            resolve(img)
        }
        img.onerror = function (e) {
            reject(e)
        }
    })
}

export function urlToBase64(url) {
    const img = new Image()
    img.crossOrigin = 'Anonymous'

    return new Promise(function (resolve, reject) {
        img.src = `${url}?t=${+new Date()}`
        img.onload = function () {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = img.width
            canvas.height = img.height
            ctx.drawImage(img, 0, 0)
            resolve(canvas.toDataURL())
        }
        img.onerror = function (e) {
            reject(e)
        }
    })
}

export function b64toBlob(dataUrl, contentType?) {
    const binary = atob(dataUrl.split(',')[1])
    const array = []
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
    }

    return new Blob([new Uint8Array(array)], { type: contentType || 'image/jpeg' })
}

export function asyncData(data) {
    return of(data).pipe(take(1))
}

export function trackerStatus(tracker, slowSpeed) {
    let text = 'Offline'
    if (tracker.login === 1 && tracker.is_moving === 1) {
        if (slowSpeed > 0 && slowSpeed > tracker.speed) {
            text = 'Slowing'
        } else {
            text = 'Moving'
        }
    }
    if (tracker.login === 1 && tracker.is_moving === 0) {
        text = 'Stopped'
    }

    return text
}

export function objectBooleanToNumber(object: Object) {
    if (isObject(object)) {
        Object.keys(object).forEach((key) => {
            const value = object[key]
            if (isBoolean(value)) {
                object[key] = Number(value)
            }

            if (isObject(value)) {
                objectBooleanToNumber(value)
            }
        })
    }
}

export function dateToUnixTime(date: Date) {
    return moment(date).unix()
}

export function getTrackByIdFormStore(id, store) {
    const trackers = store.selectSnapshot((state) => state.session.trackers) || []

    return trackers.find((item) => item.tid === id)
}

export function isArrayItemsEqual(arr1 = [], arr2 = []) {
    if (arr1.length !== arr2.length) {
        return false
    }

    const arr1IdStr = arr1
        .map((item) => item.id + item.status)
        .sort((prev, current) => prev - current)
        .join('')
    const arr2IdStr = arr2
        .map((item) => item.id + item.status)
        .sort((prev, current) => prev - current)
        .join('')

    return arr1IdStr === arr2IdStr
}

export function preProcessFence(shape: GeoFence | SimpleRegion) {
    if (shape.shape === 0) {
        // circle
        const circle = shape.circle
        shape.circle.latitude = decodeLatLng(circle.latitude)
        shape.circle.longitude = decodeLatLng(circle.longitude)

        return shape
    }

    shape.polygon = shape.polygon.map((item) => {
        return {
            latitude: decodeLatLng(item.latitude),
            longitude: decodeLatLng(item.longitude)
        }
    })

    return shape
}

export function minDistance(paths) {
    const disArr = []
    paths.forEach((item, index) => {
        const firstPoint = new google.maps.LatLng(item.lng, item.lat)

        for (let i = index + 1; i < paths.length; i++) {
            const temp = paths[i]
            const otherPoint = new google.maps.LatLng(temp.lng, temp.lat)
            const d = google.maps.geometry.spherical.computeDistanceBetween(firstPoint, otherPoint)
            disArr.push(d)
        }
    })

    return Math.round(_.min(disArr))
}

export function getIconSrc(icon: number) {
    let num: number
    if (icon < 50) {
        num = 1
    } else if (50 < icon && icon < 100) {
        num = 2
    } else if (100 < icon && icon < 150) {
        num = 3
    } else if (150 < icon && icon < 200) {
        num = 4
    } else {
        num = 5
    }
    const str = `/assets/images/icon/icon${num}.png`

    return str
}

export function handleMaintenanceAlert() {
    return
}
