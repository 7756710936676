import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map } from 'rxjs/operators'
import { NbResponse } from '../../models'
import {
    DrivingTimeDetail, Elog, FuelState, GeoFenceList, GeoFenceVisit, IFTA, IFTACalculate, Maintenance, MaintenanceHistory,
    MultipleDrivingTimeTotal, MultipleFuel,
    MultipleMileage,
    MultipleSpeed, OrangeWire, PowerCutOffLog, ReportDTC,
    ReportMeta,
    SingleDrivingTimeTotal, SingleFuel,
    SingleMileage,
    SingleSpeed, SpeedPie,
    SpeedViolationDetail,
    SpeedViolationPie,
    SpeedViolationTotal,
    Summaries,
    Temperature, TrackerRoute,
    Travel,
    Vehicle,
} from './report.model'
import { decodeLatLng } from '../../util'


@Injectable()
export class ReportService {

    constructor(
        private http: HttpClient,
    ) {
    }

    loadSummaries(params) {
        return this.http.get(ApiAddress.REPORTS_SUMMARIES, {params}).pipe(
            map((res: NbResponse<Summaries[], ReportMeta>) => (<[Summaries[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadTravel(params) {
        return this.http.get(ApiAddress.REPORTS_TRAVEL, {params}).pipe(
            map((res: NbResponse<Travel, ReportMeta>) => (<[Travel, ReportMeta]>[res.data, res.meta])),
            map(([travel, meta]) => {
                travel.stopping_points = travel.stopping_points
                    .map(point => {
                        point.latitude = decodeLatLng(point.latitude)
                        point.longitude = decodeLatLng(point.longitude)
                        point.events = travel.events.filter(event =>
                            event.start_time < point.end_time && event.start_time > point.start_driving_time
                        )

                        return point
                    }).reverse()

                return <[Travel, ReportMeta]>[travel, meta]
            })
        )
    }

    loadMileage(params) {
        return this.http.get(ApiAddress.REPORTS_MILEAGE, {params}).pipe(
            map((res: NbResponse<SingleMileage[] | MultipleMileage[], ReportMeta>) => (
                <[SingleMileage[] | MultipleMileage[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadDrivingTimeTotal(params) {
        return this.http.get(ApiAddress.REPORTS_DRIVINGTIME_TOTAL, {params}).pipe(
            map((res: NbResponse<SingleDrivingTimeTotal[] | MultipleDrivingTimeTotal[], ReportMeta>) => (
                <[SingleDrivingTimeTotal[] | MultipleDrivingTimeTotal[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadDrivingTimeDetail(params) {
        return this.http.get(ApiAddress.REPORTS_DRIVINGTIME_DETAIL, {params}).pipe(
            map((res: NbResponse<DrivingTimeDetail[], ReportMeta>) => (
                <[DrivingTimeDetail[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadTemperature(params) {
        return this.http.get(ApiAddress.REPORTS_TEMPERATURE, {params}).pipe(
            map((res: NbResponse<Temperature[] | Temperature, ReportMeta>) => (
                <[Temperature[] | Temperature, ReportMeta]>[res.data, res.meta]))
        )
    }

    loadSpeedViolationPie(params) {
        return this.http.get(ApiAddress.REPORTS_SPEEDVIOLATION_PIE, {params}).pipe(
            map((res: NbResponse<SpeedViolationPie, ReportMeta>) => (
                <[SpeedViolationPie, ReportMeta]>[res.data, res.meta]))
        )
    }

    loadSpeedViolationTotal(params) {
        return this.http.get(ApiAddress.REPORTS_SPEEDVIOLATION_TOTAL, {params}).pipe(
            map((res: NbResponse<SpeedViolationTotal, ReportMeta>) => (
                <[SpeedViolationTotal, ReportMeta]>[res.data, res.meta]))
        )
    }


    loadSpeedViolationDetail(params) {
        return this.http.get(ApiAddress.REPORTS_SPEEDVIOLATION_DETAIL, {params}).pipe(
            map((res: NbResponse<SpeedViolationDetail[], ReportMeta>) => (
                <[SpeedViolationDetail[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadAverSpeed(params) {
        return this.http.get(ApiAddress.REPORTS_SPEED_AVERSPEED, {params}).pipe(
            map((res: NbResponse<MultipleSpeed[] | SingleSpeed[], ReportMeta>) => (
                <[MultipleSpeed[] | SingleSpeed[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadSpeedPie(params) {
        return this.http.get(ApiAddress.REPORTS_SPEED_PIE, {params}).pipe(
            map((res: NbResponse<SpeedPie, ReportMeta>) => (
                <[SpeedPie, ReportMeta]>[res.data, res.meta]))
        )
    }

    loadFuel(params) {
        return this.http.get(ApiAddress.REPORTS_FUEL, {params}).pipe(
            map((res: NbResponse<SingleFuel[] | MultipleFuel[], ReportMeta>) => (
                <[SingleFuel[] | MultipleFuel[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadWorkHour(params) {
        return this.http.get(ApiAddress.REPORTS_WORKHOUR, {params}).pipe(
            map((res: NbResponse<any, ReportMeta>) => res.meta)
        )
    }

    loadRouteAnalysis(params) {
        return this.http.get(ApiAddress.REPORTS_ROUTE, {params}).pipe(
            map((res: NbResponse<TrackerRoute[], ReportMeta>) => res.data),
            map(data => {
                data.forEach(item => {
                    item.stopping_points.map(point => {
                        point.latitude = decodeLatLng(point.latitude)
                        point.longitude = decodeLatLng(point.longitude)
                    })

                    item.segments.forEach(segment => {
                        segment.map(point => {
                            point.latitude = decodeLatLng(point.latitude)
                            point.longitude = decodeLatLng(point.longitude)
                        })
                    })
                })

                return data
            })
        )
    }

    loadVehicles(params) {
        return this.http.get(ApiAddress.VEHICLES, {params}).pipe(
            map((res: NbResponse<Vehicle[]>) => res.data)
        )
    }

    getMaintenanceReport(params) {
        return this.http.get(ApiAddress.REPORTS_MAINTENANCE, {params}).pipe(
            map((res: NbResponse<any, any>) => (<[any, any]>[res.data, res.meta])),
        )
    }

    getMaintenanceHistory( body) {
        return this.http.get(ApiAddress.MAINTENANCE_REPORT, { params: body})
        .pipe(
          map((res: NbResponse<any>) => {
              return res
          })
        )
    }

    loadOrangeWire(params) {
        return this.http.get(ApiAddress.REPORTS_ORANGEWIRE, {params}).pipe(
            map((res: NbResponse<OrangeWire, ReportMeta>) => (<[OrangeWire, ReportMeta]>[res.data, res.meta])),
            map(([orangeWire, meta]) => {
                if (orangeWire.points) {
                    orangeWire.points.forEach(point => {
                        point.latitude = decodeLatLng(point.latitude)
                        point.longitude = decodeLatLng(point.longitude)
                    })
                }

                return <[OrangeWire, ReportMeta]>[orangeWire, meta]
            })
        )
    }

    loadElog(params) {
        return this.http.get(ApiAddress.REPORTS_ELOG, {params}).pipe(
            map((res: NbResponse<Elog[], ReportMeta>) => (<[Elog[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadPowerCutOffLog(params) {
        return this.http.get(ApiAddress.REPORTS_OIL_CONTROL, {params}).pipe(
          map((res: NbResponse<PowerCutOffLog[], ReportMeta>) => (<[PowerCutOffLog[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadIFTA(params) {
        return this.http.get(ApiAddress.REPORTS_IFTA, {params}).pipe(
            map((res: NbResponse<IFTA[], ReportMeta>) => (<[IFTA[], ReportMeta]>[res.data, res.meta]))
        )
    }

    getStates() {
        return this.http.get(ApiAddress.STATES).pipe(
            map((res: NbResponse<string[]>) => res.data)
        )
    }

    loadGeoFenceList() {
        return this.http.get(ApiAddress.REGIONS).pipe(
            map((res: NbResponse<GeoFenceList[]>) => res.data)
        )
    }

    loadGeoFenceVisit(params) {
        return this.http.get(ApiAddress.REPORTS_GEOFENCEVISIT, {params}).pipe(
            map((res: NbResponse<GeoFenceVisit[], ReportMeta>) => (<[GeoFenceVisit[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadIFTACalculateTime() {
        return this.http.get(ApiAddress.IFTACALCULATETIME).pipe(
            map((res: NbResponse<string[]>) => res.data)
        )
    }

    loadIFTACalculate(params) {
        return this.http.get(ApiAddress.IFTACALCULAT, {params}).pipe(
            map((res: NbResponse<IFTACalculate[], ReportMeta>) => (<[IFTACalculate[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadIFTAFuelState(params) {
        return this.http.get(ApiAddress.IFTAFUELSTATE, {params}).pipe(
            map((res: NbResponse<FuelState[], ReportMeta>) => (<[FuelState[], ReportMeta]>[res.data, res.meta]))
        )
    }

    loadReportDTC(params) {
        return this.http.get(ApiAddress.REPORT_DTC, {params}).pipe(
          map((res: NbResponse<ReportDTC, ReportMeta>) => (<[ReportDTC, ReportMeta]>[res.data, res.meta]))
        )
    }

    loadMaintenances() {
        return this.http.get(ApiAddress.MAINTENANCES).pipe(
            map((res: NbResponse<Maintenance[], ReportMeta>) => (<[Maintenance[], ReportMeta]>[res.data, res.meta]))
        )
    }

    updateMaintenance(id, body) {
        return this.http.put(ApiAddress.MAINTENANCESID, body, {params: {id}})
    }

    loadMaintenanceServices(id) {
        return this.http.get(ApiAddress.MAINTENANCE_SERVICES, {params: {id}}).pipe(
            map((res: NbResponse<any[]>) => res.data)
        )
    }

    addMaintenanceService(id, body) {
        return this.http.post(ApiAddress.MAINTENANCE_SERVICES, body, {params: {id}})
    }

    updateMaintenanceService(id, sid, body) {
        return this.http.put(ApiAddress.MAINTENANCE_SERVICE, body, {params: {id, sid}})
    }

    deleteMaintenanceService(id, sid) {
        return this.http.delete(ApiAddress.MAINTENANCE_SERVICE, {params: {id, sid}})
    }

    resetMaintenanceService(id, sid) {
        return this.http.post(ApiAddress.MAINTENANCE_SERVICE_RESET, {}, {params: {id, sid}})
    }

    updateMaintenanceLog(id, body) {
        return this.http.put(ApiAddress.MAINTENANCE_LOG, body, {params: {id}})
    }
}


