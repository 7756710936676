import { AnnouncementService } from './states/announcement/announcement.service'
import { AuthenticationService } from './states/authentication/authentication.service'
import { AlertService } from './states/alert/alert.service'
import { BusPositionService } from './states/bus-position/bus-position.service'
import { PoiService } from './states/poi/poi.service'
import { ProfileService } from './states/profile/profile.service'
import { PrivilegesService } from './states/privileges/privileges.service'
import { ReportTaskService } from './states/report tasks/report-task.service'
import { SharePositionService } from './states/share-position/share-position.service'
import { TrackerService } from './states/tracker/tracker.service'
import { SessionService } from './states/session/session.service'
import { ReportService } from './states/report/report.service'
import { ShareAccountService } from './states/share-account/share-account.service'
import { GlobalActionService } from './states/global-actions/global-action.service'
import { CrossPlatformService } from './service/cross-platform.service'
import { TripReplayService } from './states/trip-replay/trip-replay.service'
import { PushService } from './states/push/push.service'
import { GuideService } from './states/guide/guide.service'
import { NotificationsService } from './states/notifications/notifications.service'
import { GeoFenceService } from './states/geo-fence/geo-fence.service'
import { MaintenanceService } from './states/maintenance/maintenance.service'
import { AvatarService } from './states/avatar/avatar.service'
import { FleetService } from './states/fleet/fleet.service'
import { LiveTalkService } from './states/live-talk/live-talk.service'
import { WebsocketService } from './states/websocket/websocket.service'

export const CoreServices = [
    SessionService,
    AuthenticationService,
    AlertService,
    PoiService,
    ProfileService,
    PrivilegesService,
    TrackerService,
    ReportService,
    ShareAccountService,
    GlobalActionService,
    CrossPlatformService,
    TripReplayService,
    PushService,
    GuideService,
    ShareAccountService,
    NotificationsService,
    GeoFenceService,
    MaintenanceService,
    AvatarService,
    FleetService,
    SharePositionService,
    AnnouncementService,
    BusPositionService,
    ReportTaskService,
    LiveTalkService,
    WebsocketService
]
