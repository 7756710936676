import { Tracker } from './bus-position.model'

export class SetBuses {
    static readonly type = '[Bus Position] set trackers'

    constructor(public payload: Tracker[]) {
    }
}

export class UpdateBusPosition {
    static readonly type = '[Bus Position] update bus position info'

    constructor(public payload: Tracker) {
    }
}

export class SetMapReady {
    static readonly type = '[Bus Position] bus position map ready'

    constructor(public payload: boolean) {
    }
}
