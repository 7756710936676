import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('app/pages/main/main.module').then(m => m.MainModule),
        data: { preload: true }
    },
    {
        path: 'entrance',
        loadChildren: () => import('app/pages/entrance/entrance.module').then(m => m.EntranceModule),
    },
    // 在新页面展示数据
    {
       path: 'show-data',
       loadChildren: () => import('app/pages/show-data/show-data.module').then(m => m.ShowDataModule),
    },
    {
        path: 'login',
        redirectTo: 'entrance/login',
        pathMatch: 'full',
    },
    {
        path: 'register',
        redirectTo: 'entrance/register',
        pathMatch: 'full',
    },
    {
        path: 'forget',
        redirectTo: 'entrance/forget',
        pathMatch: 'full',
    },
    {
        path: 'upgrade-tips',
        redirectTo: 'entrance/upgrade-tips',
        pathMatch: 'full'
    },
    {
        path: 'share-position/:token',
        loadChildren: () => import('app/pages/main/share-position/share-position.module').then(m => m.SharePositionModule),
    },
    {
        path: 'bus-position/:token',
        loadChildren: () => import('app/pages/main/bus-position/bus-position.module').then(m => m.BusPositionModule),
    },
    {
        path: 'bus-line',
        loadChildren: () => import('app/pages/bus-line/bus-line.module').then((m) => m.BusLineModule)
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class PagesRoutingModule {
    constructor() {
    }
}
