import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { Router } from '@angular/router'
import { EndGuide, FulfillStep, NextGuide } from './guide.actions'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map } from 'rxjs/operators'
import { NbResponse } from '../../models'

@Injectable()
export class GuideService {

    constructor(
        private store: Store,
        private router: Router,
        private http: HttpClient
    ) {}

    getCurrentStep() {
        const steps = this.store.selectSnapshot(state => state.guide.steps)
        const stepIndex = this.store.selectSnapshot(state => state.guide.stepIndex)

        return steps[stepIndex]
    }

    next() {
        this.store.dispatch(new FulfillStep())
        this.store.dispatch(new NextGuide())
        this.startGuide()
    }

    startGuide() {
        const currentStep = this.getCurrentStep()
        if (currentStep) {
            this.router.navigate([currentStep.router])
        } else {
            this.endGuide()
        }
    }

    endGuide() {
        const firstStep = this.store.selectSnapshot(state => state.guide.steps)[0]
        if (firstStep) {
            this.router.navigate([firstStep.router])
        }
        this.store.dispatch(new EndGuide())
    }

    markFirstLogin(value) {
        return this.http.put(ApiAddress.FIRST_LOGIN, {first_login: value}).pipe(
            map((res: NbResponse<any>) => res.data),
        )
    }
}
