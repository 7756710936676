import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { NbResponse } from '../../models'
import { ImmediatePhoto } from '../fleet/fleet.model'
import { CacheImmediatePhoto } from '../live-video/live-video.actions'
import { Connected, ConnectedError, ConnectedFailed, ConnectParams, PushRegisterSuccess } from './push.store'
import { map } from 'rxjs/operators'
import { interval, Observable, Subscription } from 'rxjs'
import * as io from 'socket.io-client'
import { Store } from '@ngxs/store'
import { PushFence, PushFleet, PushPoi, S3Tracker } from './push.model'
import {
    AddFleet,
    AddPoi,
    AddSimpleFence,
    AddTracker,
    ChangeProfile,
    RemoveFleet,
    RemovePoi,
    RemoveSimpleFence,
    RemoveTracker,
    SetPrivileges,
    SetSosTracker,
    SetUnreadNum,
    UpdateFleet,
    UpdateFleets,
    UpdatePoi, UpdateSimpleFence,
    UpdateTracker,
} from '../session/session.actions'
import { Tracker } from '../tracker/tracker.model'
import { decodeLatLng, preProcessFence } from '../../util'
import { Profile } from '../profile/profile.model'
import { Privileges } from '../privileges/privileges.model'


@Injectable()
export class PushService {
    socket: SocketIOClient.Socket
    reConnectSub: Subscription
    constructor(
        private http: HttpClient,
        private store: Store
    ) {
    }

    register(body): Observable<ConnectParams> {
        return this.http.get(ApiAddress.WS_PUSH, {params: body})
            .pipe(map((res: NbResponse<ConnectParams>) => {
                return res.data
            }))
    }

    connect() {
        const wspush = this.store.selectSnapshot(state => state.push.wspush)
        if (this.socket) {
            this.socket.removeAllListeners()
            this.socket.close()
            this.socket = null
        }
        this.socket =  io.connect('', {
            path: '/push/2.0/socket.io',
            query: {
                packet_type: 'C1',
                push_id: wspush.wspush_id,
                psd: wspush.wspush_key,
                devid: wspush.devid,
                from: 0
            },
            multiplex: false,
            forceNew: true,
            reconnection: false,
            timeout: 30000,
            transports: ['websocket']
        })

        this.socket.on('connect', () => {
            console.log('connected')
            this.store.dispatch(new Connected())
            this.removeReconnectSub()
        })

        this.socket.on('connect_failed', (e) => {
            console.log('connect failed', e)
            this.store.dispatch(new ConnectedFailed())
            this.reConnection(e)
        })
        this.socket.on('error', (e) => {
            console.log('error', e)
            this.store.dispatch(new ConnectedError())
            this.reConnection(e)
        })
        this.socket.on('connect_error', (e) => {
            console.log('connect error', e)
            this.store.dispatch(new ConnectedError())
            this.reConnection(e)
        })
        this.socket.on('disconnect', (e) => {
            console.log('disconnect', e)
            this.store.dispatch(new ConnectedError())
            this.reConnection(e)
        })
        this.socket.on('connect_timeout', (e) => {
            console.log('connect_timeout', e)
            this.store.dispatch(new ConnectedFailed())
            this.reConnection(e)
        })
        this.socket.on('api/resp', (pkg) => {
            console.log(pkg)
            this.resolvePackage(pkg)
        })
    }

    destroy() {
        if (this.socket) {
            this.socket.close()
            this.socket = null
        }
        this.removeReconnectSub()
    }

    private removeReconnectSub() {
        if (this.reConnectSub) {
            this.reConnectSub.unsubscribe()
            this.reConnectSub = null
        }
    }

    private reConnection(message: string) {
        if (/401/.test(message)) {
            this.register({from: 0}).subscribe((wspush) => {
                this.store.dispatch(new PushRegisterSuccess(wspush))
                this.connect()
            }, () => {
                this.intervalReconnection()
            })
        } else {
            this.intervalReconnection()
        }
    }

    private intervalReconnection() {
        if (this.reConnectSub) {
            return
        }

        this.reConnectSub = interval(15000).subscribe(() => {
            this.connect()
        })
    }

    private resolvePackage(pkg) {
        switch (pkg.packet_type) {
            case 'S3': {
                const trackers = this.pkgToModelData<S3Tracker[]>(pkg)
                trackers.forEach((tracker => {
                    // 新增tracker
                    switch (tracker.optype) {
                        case 0: {
                            this.store.dispatch(new RemoveTracker(tracker)); break
                        }
                        case 1: {
                            this.store.dispatch(new AddTracker(tracker)); break
                        }
                        default: {
                            console.warn('[push] server response data error')
                        }
                    }
                }))

                return ''
            }
            case 'S4': {
                // 登录状态改变
                const s4Trackers = this.pkgToModelData<Tracker[]>(pkg)
                s4Trackers.forEach(tracker => {
                    const updates = {
                        id: tracker.id,
                        login: tracker.login
                    }
                    this.store.dispatch(new UpdateTracker(updates))
                })

                return
            }
            case 'S5': {
                // tracker location change
                const s5Trackers = this.pkgToModelData<Tracker[]>(pkg)
                s5Trackers.forEach(tracker => {
                    if (tracker.longitude) {
                        tracker.longitude = decodeLatLng(tracker.longitude)
                    }

                    if (tracker.latitude) {
                        tracker.latitude = decodeLatLng(tracker.latitude)
                    }
                    this.store.dispatch(new UpdateTracker(tracker))
                })

                return ''
            }
            case 'S6':
            case 'S7': {
                // tracker status changes
                const s7Trackers = this.pkgToModelData<Tracker[]>(pkg)
                s7Trackers.forEach(tracker => {
                    if (tracker.longitude) {
                        tracker.longitude = decodeLatLng(tracker.longitude)
                    }

                    if (tracker.latitude) {
                        tracker.latitude = decodeLatLng(tracker.latitude)
                    }
                    this.store.dispatch(new UpdateTracker(tracker))

                    if (tracker.fleets) {
                        this.store.dispatch(new UpdateFleets(tracker))
                    }
                })

                return ''
            }
            case 'S8': {
                // profile change
                const profile = this.pkgToModelData<Profile>(pkg)
                this.store.dispatch(new ChangeProfile(profile))

                return ''
            }
            case 'S9': {
                // alert
                const alertCount = pkg.res.alert_cnt
                this.store.dispatch(new SetUnreadNum(alertCount))

                return ''
            }
            case 'S10': {
                // poi
                const pois = this.pkgToModelData<PushPoi[]>(pkg)
                pois.forEach(poi => {
                    switch (poi.optype) {
                        case 0: {
                            this.store.dispatch(new RemovePoi(poi))

                            return ''
                        }
                        case 1: {
                            this.store.dispatch(new AddPoi(poi))

                            return ''
                        }
                        case 2: {
                            delete poi.optype
                            if (poi.latitude) {
                                poi.latitude = decodeLatLng(poi.latitude)
                            }
                            if (poi.longitude) {
                                poi.longitude = decodeLatLng(poi.longitude)
                            }
                            this.store.dispatch(new UpdatePoi(poi))

                            return ''
                        }
                        default: {
                            console.warn('[push] server response data error')
                        }
                    }
                })

                return ''
            }
            case 'S11': {
                const sosAlerts = pkg.res.filter(alert => alert.category === 5)
                const sosTrackerIds = sosAlerts.map(alert => alert.tid)
                this.store.dispatch(new SetSosTracker(sosTrackerIds))

                return ''
            }
            case 'S13': {
                const privilege = this.store.selectSnapshot(state => state.session.privileges)
                const data = this.pkgToModelData<Privileges>(pkg)
                const newPrivilege = {...privilege, ...data }
                this.store.dispatch(new SetPrivileges(newPrivilege))

                return ''
            }
            case 'S14': {
                const s14Fleets = this.pkgToModelData<PushFleet[]>(pkg)
                s14Fleets.forEach(fleet => {
                    const optype = fleet.optype
                    delete fleet.optype
                    switch (optype) {
                        case 0:
                            this.store.dispatch(new RemoveFleet(fleet))
                            break
                        case 1:
                            this.store.dispatch(new AddFleet(fleet))
                            break
                        case 2:
                            this.store.dispatch(new UpdateFleet(fleet))
                            break
                        default:
                            console.warn('[push] server response data error')
                    }
                })

                return
            }
            case 'S16': {
                const newImmediatePhoto = this.pkgToModelData<ImmediatePhoto>(pkg)
                if (newImmediatePhoto[0].longitude) {
                    newImmediatePhoto[0].longitude = decodeLatLng(newImmediatePhoto[0].longitude)
                }

                if (newImmediatePhoto[0].latitude) {
                    newImmediatePhoto[0].latitude = decodeLatLng(newImmediatePhoto[0].latitude)
                }
                // 立即拍照类型前端手动加上
                newImmediatePhoto[0].type = 1
                this.store.dispatch(new CacheImmediatePhoto(newImmediatePhoto))

                return
            }
            case 'S18': {
                // geo fences
                const fences = this.pkgToModelData<PushFence[]>(pkg)
                fences.forEach(fence => {
                    const newFence = <PushFence>{...preProcessFence(fence)}
                    delete newFence.optype
                    switch (fence.optype) {
                        case 0: {
                            this.store.dispatch(new RemoveSimpleFence(fence.id))

                            return ''
                        }
                        case 1: {
                            this.store.dispatch(new AddSimpleFence(fence))

                            return ''
                        }
                        case 2: {
                            delete fence.optype

                            this.store.dispatch(new UpdateSimpleFence(fence))

                            return ''
                        }
                        default: {
                            console.warn('[push] server response data error')
                        }
                    }
                })

                return ''
            }
            default: {
                console.warn('[push] response data format error')
            }
        }
    }

    private pkgToModelData<T>(pkg) {
        return <T>pkg.res
    }
}
