import { ElementRef, Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class GlobalActionService {
    private actions$: BehaviorSubject<ElementRef[]> = new BehaviorSubject([])
    private actions: ElementRef[] = []
    private content: ElementRef = null
    private content$: BehaviorSubject<ElementRef> = new BehaviorSubject<ElementRef>(null)
    private state = {
        legendOpen: false
    }


    constructor() {
    }

    changeContent(content: ElementRef) {
        this.content = content
        this.content$.next(content)
    }

    addAction(action: ElementRef) {
        this.actions.push(action)
        this.actions$.next(this.actions)
    }

    removeAction(action: ElementRef) {
        this.actions = this.actions.filter((item) => item !== action)
        this.actions$.next(this.actions)
    }

    removeContent() {
        this.content = null
        this.content$.next(null)
    }

    reset() {
        this.actions.forEach((item) => {
            item.nativeElement.parentElement.removeChild(item.nativeElement)
        })
        if (this.content) {
            const contentDom: HTMLElement = this.content.nativeElement
            contentDom.parentElement.removeChild(contentDom)
        }
        this.actions = []
        this.content = null
        this.actions$.next(this.actions)

        this.removeContent()
    }

    getActions$() {
        return this.actions$
    }

    getContent$() {
        return this.content$
    }

    getState() {
        return this.state
    }

    setState(updates) {
        this.state = { ...this.state, ...updates }
    }
}
