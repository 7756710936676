import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { Maintenance, ServiceType, Vehicle } from './maintenance.model'

@Injectable()
export class MaintenanceService {

    constructor(
        private http: HttpClient,
    ) {
    }

    // maintenance
    loadMaintenances(obj) {
        return this.http.get(ApiAddress.MAINTENANCES, {params: obj}).pipe(
            map((res: NbResponse<Maintenance[], any>) => (<[Maintenance[], any]>[res.data, res.meta])),
        )
    }

    loadOverdueMaintenances(obj) {
        return this.http.get(ApiAddress.MAINTENANCES, {params: obj}).pipe(
            map((res: NbResponse<Maintenance[], any>) => (<[Maintenance[], any]>[res.data, res.meta])),
        )
    }

    getMaintenanceById(id) {
        return this.http.get(ApiAddress.MAINTENANCESID, {params: {id}}).pipe(
            map((res: NbResponse<Maintenance>) => res.data),
        )
    }

    addNewMaintenance(body) {
        return this.http.post(ApiAddress.MAINTENANCES, body)
    }

    updateMaintenanceById(id, body) {
        return this.http.put(ApiAddress.MAINTENANCESID, body, {params: {id}}).pipe(
            map((res: NbResponse<Maintenance>) => res.data),
        )
    }

    deleteMaintenanceById(id) {
        return this.http.delete(ApiAddress.MAINTENANCESID, {params: {id}})
    }

    // service type
    getAllServiceTypes() {
        return this.http.get(ApiAddress.SERVICETYPE).pipe(
            map((res: NbResponse<ServiceType[]>) => res.data),
        )
    }

    addNewServiceType(body) {
        return this.http.post(ApiAddress.SERVICETYPE, body)
    }

    getServiceTypeById(id) {
        return this.http.get(ApiAddress.SERVICETYPEID, {params: {id}}).pipe(
            map((res: NbResponse<ServiceType>) => res.data),
        )
    }

    deleteServiceTypeById(id) {
        return this.http.delete(ApiAddress.SERVICETYPEID, {params: {id}})
    }

    updateServiceTypeById(id, body) {
        return this.http.put(ApiAddress.SERVICETYPEID, body, {params: {id}})
    }

    // vehicle
    getAllVehicles() {
        return this.http.get(ApiAddress.VEHICLES).pipe(
            map((res: NbResponse<Vehicle[]>) => res.data)
        )
    }

    getVehiclesByPage(obj) {
        return this.http.get(ApiAddress.VEHICLES, {params: obj}).pipe(
            map((res: NbResponse<Vehicle[], any>) => (<[Vehicle[], any]>[res.data, res.meta]))
        )
    }

    getVehicleById(id) {
        return this.http.get(ApiAddress.VEHICLESID, {params: {id}}).pipe(
            map((res: NbResponse<Vehicle>) => res.data)
        )
    }

    addNewVehicle(body) {
        return this.http.post(ApiAddress.VEHICLES, body)
    }

    deleteVehicleById(id) {
        return this.http.delete(ApiAddress.VEHICLESID, {params: {id}})
    }

    updateVehicleById(id, body) {
        return this.http.put(ApiAddress.VEHICLESID, body, {params: {id}})
    }
}
