import { NgModule } from '@angular/core'
import { PagesRoutingModule } from './pages-routing.module'
import { SITE_CONFIG_TOKEN } from '../../../../partials/site-config'
import { SiteConfig } from './site-config'
import { CROSS_PLATFORM_TOKEN } from '../../../../business/tokens'
import { PlatformFeature } from './platform-feature'

@NgModule({
    imports: [
        PagesRoutingModule,
    ],
    declarations: [],
    providers: [
        { provide: SITE_CONFIG_TOKEN, useValue: SiteConfig },
        { provide: CROSS_PLATFORM_TOKEN, useValue: PlatformFeature }
    ]
})
export class PagesModule {
}
