import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { Notifications } from './notification.model'

@Injectable()
export class NotificationsService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getNotification(id) {
        return this.http.get(ApiAddress.NOTIFICATIONS, {params: {id}}).pipe(
            map((res: NbResponse<Notifications>) => res.data)
        )
    }

    saveNotification(id, body) {
        return this.http.put(ApiAddress.NOTIFICATIONS, body, {params: {id}}).pipe(
            map((res: NbResponse<any>) => res.data)
        )
    }
}
