import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { map, tap } from 'rxjs/operators'
import { ApiAddress } from '../../core.api'
import { NbResponse } from '../../models'
import { decodeLatLng } from '../../util'
import { SetFleets } from '../session/session.actions'
import {
    Department,
    Driver,
    DriverVehicle,
    FileTask,
    Fleet,
    ImmediatePhoto,
    IntervalPhoto,
    PageInfo,
    PhotoMeta,
    ScoreDriver,
    ScoreEvent,
    ScoreSetting,
    UnallocatedDriversTrackers,
    UnallocatedTracker,
    UsersManagerDriver,
    UsersManagerGroup,
    VideoFile
} from './fleet.model'

@Injectable()
export class FleetService {
    constructor(private http: HttpClient, private store: Store) {}

    loadFleets() {
        return this.http.get(ApiAddress.FLEETS).pipe(
            map((res: NbResponse<Fleet[]>) => res.data),
            tap((fleets) => {
                this.store.dispatch(new SetFleets(fleets))
            })
        )
    }

    loadFleetsByPage(page) {
        return this.http
            .get(ApiAddress.FLEETS, { params: { page } })
            .pipe(map((res: NbResponse<Fleet[], PageInfo>) => <[Fleet[], PageInfo]>[res.data, res.meta]))
    }

    addFleet(body) {
        return this.http.post(ApiAddress.FLEETS, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    getFleetById(id) {
        return this.http.get(ApiAddress.FLEET, { params: { id } }).pipe(map((res: NbResponse<Fleet>) => res.data))
    }

    editFleet(id, body) {
        return this.http.put(ApiAddress.FLEET, body, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    deleteFleet(id) {
        return this.http.delete(ApiAddress.FLEET, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    // users manager
    // post {Groups/Drivers} 数据
    createUsersManagerGroups(body) {
        return this.http.post(ApiAddress.DEPARTMENTS, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    createUsersManagerDrivers(body) {
        return this.http.post(ApiAddress.DRIVERS, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    // delete {Groups/Drivers} 数据
    deleteUsersManagerGroups(id) {
        return this.http
            .delete(ApiAddress.DEPARTMENTS_ID, {
                params: { id }
            })
            .pipe(map((res: NbResponse<any>) => res.data))
    }
    deleteUsersManagerDrivers(id) {
        return this.http.delete(ApiAddress.DEPARTMENTS_ID, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    // put {Groups/Drivers} 数据
    editUsersManagerGroups(body, id) {
        return this.http
            .put(ApiAddress.DEPARTMENTS_ID, body, {
                params: { id }
            })
            .pipe(map((res: NbResponse<any>) => res.data))
    }
    editUsersManagerDrivers(body, id) {
        return this.http.put(ApiAddress.DRIVERS_ID, body, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    // get {Groups/Drivers} 数据
    getUsersManagerGroups() {
        return this.http.get(ApiAddress.DEPARTMENTS).pipe(map((res: NbResponse<UsersManagerGroup[]>) => res.data))
    }
    getUsersManagerDrivers() {
        return this.http.get(ApiAddress.DRIVERS).pipe(map((res: NbResponse<UsersManagerDriver[]>) => res.data))
    }

    // get {Groups/Drivers} ID数据
    getUsersManagersGroupsId(id) {
        return this.http.get(ApiAddress.DEPARTMENTS_ID, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }
    getUsersManagersDriversId(id) {
        return this.http.get(ApiAddress.DRIVERS_ID, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    // groups 和 usersManager groups 接口相同(但在 share 组件中也有引用暂不删除)
    getDepartments() {
        return this.http.get(ApiAddress.DEPARTMENTS).pipe(map((res: NbResponse<Department[]>) => res.data))
    }

    createDepartment(body) {
        return this.http.post(ApiAddress.DEPARTMENTS, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    getDepartmentById(id) {
        return this.http.get(ApiAddress.DEPARTMENTS_ID, { params: { id } }).pipe(map((res: NbResponse<Department>) => res.data))
    }

    editDepartment(body, id) {
        return this.http.put(ApiAddress.DEPARTMENTS_ID, body, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    deleteDepartment(id) {
        return this.http.delete(ApiAddress.DEPARTMENTS_ID, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    getUnallocatedTrackers() {
        return this.http.get(ApiAddress.DEPARTMENTS_TRACKERS_UNALLOCATED).pipe(map((res: NbResponse<UnallocatedTracker[]>) => res.data))
    }

    // driver management
    getDrivers() {
        return this.http.get(ApiAddress.DRIVERS).pipe(map((res: NbResponse<Driver[]>) => res.data))
    }

    createDriver(body) {
        return this.http.post(ApiAddress.DRIVERS, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    getDriverById(id) {
        return this.http.get(ApiAddress.DRIVERS_ID, { params: { id } }).pipe(map((res: NbResponse<Driver>) => res.data))
    }

    editDriverById(body, id) {
        return this.http.put(ApiAddress.DRIVERS_ID, body, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    deleteDriverById(id) {
        return this.http.delete(ApiAddress.DRIVERS_ID, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    // access control
    getDriversVehicles() {
        // 查询司机和车辆分配信息
        return this.http.get(ApiAddress.DRIVERS_VEHICLES).pipe(map((res: NbResponse<DriverVehicle[]>) => res.data))
    }

    allocateDriversVehicles(body) {
        // 给司机分配车辆
        return this.http.post(ApiAddress.DRIVERS_VEHICLES, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    unallocatedDriversVehicles() {
        // 没有关联的司机和车辆
        return this.http.get(ApiAddress.DRIVERS_VEHICLES_UNALLOCATED).pipe(map((res: NbResponse<UnallocatedDriversTrackers[]>) => res.data))
    }

    getAllocationById(id) {
        return this.http.get(ApiAddress.DRIVERS_VEHICLES_ID, { params: { id } }).pipe(map((res: NbResponse<DriverVehicle>) => res.data))
    }

    editAllocationById(body, id) {
        return this.http.put(ApiAddress.DRIVERS_VEHICLES_ID, body, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    deleteAllocationById(id) {
        return this.http.delete(ApiAddress.DRIVERS_VEHICLES_ID, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    punchOutById(id) {
        // web端只有 punch out 操作
        const body = { status: 1 }

        return this.http.post(ApiAddress.TRACKERS_PUNCH, body, { params: { id } }).pipe(map((res: NbResponse<any>) => res.data))
    }

    // camera
    getImmediatePhotos(params) {
        return this.http.get(ApiAddress.PHOTO_INSTRUCTIONS, { params }).pipe(
            map((res: NbResponse<ImmediatePhoto[], PhotoMeta>) => {
                res.data.forEach((photo) => {
                    photo.latitude = decodeLatLng(photo.latitude)
                    photo.longitude = decodeLatLng(photo.longitude)
                })

                return <[ImmediatePhoto[], PhotoMeta]>[res.data, res.meta]
            })
        )
    }

    takePhotoImmediate(body) {
        return this.http.post(ApiAddress.PHOTO_INSTRUCTIONS, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    getVideo(query) {
        return this.http.get(ApiAddress.TRACKER_VIDEOS, { params: query }).pipe(
            map((res: NbResponse<VideoFile[]>) => res.data),
            map((files) => files.sort((prev, current) => current.start_time - prev.start_time))
        )
    }

    playVideo(tid, params) {
        return this.http.post(ApiAddress.PLAY_VIDEO, params, { params: { tid } }).pipe(map((res: NbResponse<{ url: string }>) => res.data))
    }

    stopVideo(params) {
        return this.http.delete(ApiAddress.PLAY_VIDEO, { params }).pipe(map((res: NbResponse<{ url: string }>) => res.data))
    }

    saveVideoFile(tid, body) {
        return this.http.post(ApiAddress.TASKS, body, { params: { tid } }).pipe(map((res: NbResponse<FileTask>) => res.data))
    }

    getTasks(params) {
        return this.http.get(ApiAddress.TASKS, { params }).pipe(
            map((res: NbResponse<FileTask[]>) => res.data),
            map((files) => files.sort((prev, current) => current.create_time - prev.create_time))
        )
    }

    getTaskById(id) {
        return this.http.get(ApiAddress.TASKS, { params: { id } }).pipe(map((res: NbResponse<FileTask>) => res.data))
    }

    updateTask(id, tid, body) {
        return this.http.put(ApiAddress.TASK, body, { params: { id, tid } }).pipe(map((res: NbResponse<FileTask>) => res.data))
    }

    // photo setting
    getIntervalPhotos(params) {
        return this.http
            .get(ApiAddress.PHOTO_INTERVAL, { params })
            .pipe(map((res: NbResponse<IntervalPhoto[], PhotoMeta>) => <[IntervalPhoto[], PhotoMeta]>[res.data, res.meta]))
    }

    setIntervalPhoto(body) {
        return this.http.post(ApiAddress.PHOTO_INTERVAL, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    getIntervalPhotoById(body) {
        return this.http.get(ApiAddress.PHOTO_INTERVAL).pipe(map((res: NbResponse<IntervalPhoto>) => res.data))
    }

    editIntervalPhotoById(body) {
        return this.http.put(ApiAddress.PHOTO_INTERVAL, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    getDriveOverview() {
        return this.http.get(ApiAddress.DRIVE_OVERVIEW).pipe(
            map((res: any) => {
                return res.data
            })
        )
    }

    getDriverScoreSetting() {
        return this.http.get(ApiAddress.DRIVER_SCORE_SETTING).pipe(map((res: NbResponse<ScoreSetting>) => res.data))
    }

    updateScoreSetting(body) {
        return this.http.put(ApiAddress.DRIVER_SCORE_SETTING, body).pipe(map((res: NbResponse<any>) => res.data))
    }

    getScoreDrivers(params) {
        return this.http.get(ApiAddress.DRIVER_SCORE_DRIVERS, { params }).pipe(map((res: NbResponse<ScoreDriver[]>) => res.data))
    }

    getScoreEvents(params) {
        return this.http.get(ApiAddress.DRIVER_SCORE_EVENTS, { params }).pipe(
            map((res: NbResponse<ScoreEvent[]>) => res.data),
            map((res) => {
                res.map((item) => {
                    item.location.latitude = decodeLatLng(item.location.latitude)
                    item.location.longitude = decodeLatLng(item.location.latitude)
                })

                return res
            })
        )
    }

    downloadOverviewData(params) {
        return this.http.get(ApiAddress.DOWNLOAD_SCORE, { params })
    }

    getAllDrivers() {
        return this.http.get(ApiAddress.DRIVERS_NAME).pipe(map((res: any) => res.data))
    }
}
