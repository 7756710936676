import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { CameraEvents, MoviePoint, StoppingPoint, Track, TrackMeta } from './trip-replay.model'
import { decodeLatLng } from '../../util'

@Injectable()
export class TripReplayService {

    constructor(
        private http: HttpClient,
    ) {}

    loadTrack(params) {
        return this.http.get(ApiAddress.TRACK, {params}).pipe(
            map((res: NbResponse<Track, TrackMeta>) => (<[Track, TrackMeta]>[res.data, res.meta])),
            map(([track, meta]) => {
                const cameraEvents = track.camera_events.filter(item => item.type === 1)
                track.stopping_points.map(point => {
                    point.cameraEvent = cameraEvents.find(item => item.key && item.key === point.key)
                    point.latitude = decodeLatLng(point.latitude)
                    point.longitude = decodeLatLng(point.longitude)
                })

                track.notes.map(note => {
                    note.latitude = decodeLatLng(note.latitude)
                    note.longitude = decodeLatLng(note.longitude)
                })

                track.segments.forEach(segment => {
                    segment.map(point => {
                        point.latitude = decodeLatLng(point.latitude)
                        point.longitude = decodeLatLng(point.longitude)
                    })
                })

                track.over_speeds.forEach(segment => {
                    segment.map(point => {
                        point.latitude = decodeLatLng(point.latitude)
                        point.longitude = decodeLatLng(point.longitude)
                    })
                })

                track.photos.forEach(photo => {
                    photo.latitude = decodeLatLng(photo.latitude)
                    photo.longitude = decodeLatLng(photo.longitude)
                })

                track.eld_events.forEach(eld => {
                    eld.latitude = decodeLatLng(eld.latitude)
                    eld.longitude = decodeLatLng(eld.longitude)
                    // m to km
                    eld.odometer = eld.odometer / 1000
                })

                return <[Track, TrackMeta]>[track, meta]
            })
         )
    }

    getUploadedEvents(eventIds) {
        return this.http.get(ApiAddress.TRACK_EVENTS, {params: { event_ids: eventIds, isLoading: 'false' }}).pipe(
          map((res: NbResponse<CameraEvents[]>) => res.data)
        )
    }

    uploadVideo(eventId) {
        return this.http.put(ApiAddress.TRACK_EVENTS, {event_id: eventId})
    }

    loadBaseTrack(params) {
        return this.http.get(ApiAddress.TRACK_LOCATIONS, {params}).pipe(
            map((res: NbResponse<{segments: Array<MoviePoint[]>, stopping_points: any[]}>) => res.data),
            map(data => {
                const segments = data.segments

                const trackData = {
                    segments: segments.reduce((pre, next) => {
                        return pre.concat(next)
                    }, []).map(point => {
                        point.latitude = decodeLatLng(point.latitude)
                        point.longitude = decodeLatLng(point.longitude)

                        return point
                    }),
                    stopping_points: data.stopping_points?.map(point => {
                        point.latitude = decodeLatLng(point.latitude)
                        point.longitude = decodeLatLng(point.longitude)

                        return point
                    }) || []
                }

                return trackData
            })
        )
    }

    updateEldEvent(tid, body) {
        return this.http.put(ApiAddress.ELD_EVENTS, body, {params: {tid}})
            .pipe(
                map((res: NbResponse<{segments: Array<MoviePoint[]>}>) => res.data)
            )
    }
}
