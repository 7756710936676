import { Component } from '@angular/core'

@Component({
    templateUrl: 'video-not-found.component.html',
    styleUrls: ['video-not-found.component.less']
})

export class VideoNotFoundComponent {
    constructor() {}
}
