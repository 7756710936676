import { InjectionToken } from '@angular/core'

export const SITE_CONFIG_TOKEN = new InjectionToken('site config token')

export interface ISiteConfig {
    primaryColor?: string
    siteName: string
    logo: string
    menu: MenuItem[]
    footerLinks: FooterLink[]
    copyright: string
    termsOfService?: string
    profileMenu: MenuItem[]
}

export interface MenuItem {
    url: string
    text: string
    /**
     * -1: not a link
     * 0: is a link
     * 1: is a outer link
     */
    type?: -1 | 0 | 1
    plug?: string
    icon?: string
    permissions?: string[]
    children?: MenuItem[]
}

export interface FooterLink {
    url?: string
    text?: string
    children?: FooterLink[]
}
