import { ISiteConfig } from '../../../../partials/site-config'

const thisYear = new Date().getFullYear()

export const SiteConfig: ISiteConfig = {
  primaryColor: '#f9a01b',
  siteName: 'AccuGPS',
  logo: '/assets/logo.png',
  menu: [
    {
      url: '/fleet',
      text: 'Fleet',
      type: -1,
      children: [
        {
          url: '/fleet/settings',
          text: 'Settings',
          type: 0,
        },
        {
          url: '/fleet/groups',
          text: 'Groups',
          type: 0,
          permissions: ['SHOW_GROUPS'],
        },
        {
          url: '/fleet/driver-scores',
          text: 'Drive Score',
          type: 0,
        },
        {
          url: '/fleet/users-manager',
          text: 'Users Manager',
          type: 0,
          permissions: ['SHOW_USERS_MANAGER'],
        },
        // {
        //   url: '/fleet/access-control',
        //   text: 'Access Control',
        //   type: 0,
        //   permissions: ['SHOW_ACCESS_CONTROL'],
        // },
        {
          url: '/fleet/driver-management',
          text: 'Driver Management',
          type: 0,
          permissions: ['SHOW_DRIVER_MANAGEMENT'],
        },
        // {
        //     url: '/fleet/camera',
        //     text: 'Camera',
        //     type: 0,
        // },
        // {
        //     url: '/fleet/video',
        //     text: 'Video',
        //     type: 0,
        // },
        // {
        //     url: '/fleet/photo-setting',
        //     text: 'Photo Setting',
        //     type: 0,
        // },
      ],
    },
    {
      url: '/camera',
      text: 'Camera',
      type: -1,
      children: [
        {
          url: '/camera/video',
          text: 'Video',
          type: 0,
        },
        {
          url: '/camera/photo',
          text: 'Photo',
          type: 0,
        },
      ],
    },
    {
      url: '/trip-replay',
      text: 'Trip Replay',
      type: 0,
    },
    {
      url: '/reports',
      text: 'Reports',
      type: -1,
      children: [
        {
          url: '/reports/summary',
          text: 'Summary',
          type: 0,
        },
        {
          url: '/reports/travel',
          text: 'Travel',
          type: 0,
        },
        {
          url: '/reports/mileage',
          text: 'Mileage',
          type: 0,
        },
        {
          url: '/reports/driving-time',
          text: 'Driving time',
          type: 0,
        },
        {
          url: '/reports/temperature',
          text: 'Temperature',
          type: 0,
        },
        {
          url: '/reports/speed-violation',
          text: 'Speed Violation',
          type: 0,
          permissions: ['SHOW_SPEED_VIOLATION'],
        },
        {
          url: '/reports/speed',
          text: 'Speed',
          type: 0,
        },
        {
          url: '/reports/fuel',
          text: 'Fuel',
          type: 0,
        },
        {
          url: '/reports/work-hour',
          text: 'Work Hour',
          type: 0,
          permissions: ['SHOW_WORKINGTIME'],
        },
        {
          url: '/reports/maintenance',
          text: 'Maintenance',
          type: 0,
          permissions: ['SHOW_MAINTENANCE'],
        },
        {
          url: '/reports/route-analysis',
          text: 'Route analysis',
          type: 0,
        },
        {
          url: '/reports/orange-wire',
          text: 'Orange wire',
          type: 0,
          permissions: ['SHOW_POWER_ON'],
        },
        {
          url: '/reports/elog',
          text: 'Elog',
          type: 0,
          permissions: ['SHOW_ELOG'],
        },
        {
          url: '/reports/power-cut-off-log',
          text: 'Power Cut Off Log',
          type: 0,
        },
        {
          url: '/reports/ifta',
          text: 'IFTA (State Miles)',
          type: 0,
          permissions: ['SHOW_STATE_MILEAGE'],
        },
        {
          url: '/reports/geo-fence-visit',
          text: 'Geo-fence visit',
          type: 0,
        },
        // {
        //     url: '/reports/calculate',
        //     text: 'IFTA (Tax Form)',
        //     type: 0,
        //     permissions: ['SHOW_STATE_MILEAGE'],
        // },
        {
          url: '/reports/current-info',
          text: 'Current info',
          type: 0,
        },
        {
          url: '/reports/DTC',
          text: 'DTC',
          type: 0,
        },
        // {
        //     url: '/reports/ELD',
        //     text: 'ELD',
        //     type: 0,
        // }
      ],
    },
    {
      url: '/alerts',
      text: 'Alerts',
      type: 0,
      plug: 'alert',
    },
    {
      url: '/settings',
      text: 'Settings',
      type: -1,
      children: [
        {
          url: '/settings/account',
          text: 'Account',
          type: 0,
          permissions: ['PROFILE_EDIT'],
        },
        {
          url: '/settings/tracker',
          text: 'Tracker',
          type: 0,
        },
        {
          url: '/settings/geo-fence',
          text: 'Geo-fence',
          type: 0,
        },
        {
          url: '/settings/poi',
          text: 'Point Of Interest',
          type: 0,
        },
        {
          url: '/settings/reporting',
          text: 'Reporting',
          type: 0,
        },
        {
          url: '/settings/notifications',
          text: 'Notifications',
          type: 0,
        },
        {
          url: '/settings/shared-account',
          text: 'Shared Account',
          type: 0,
        },
        // {
        //     url: '/settings/maintenance',
        //     text: 'Maintenance',
        //     type: 0,
        //     permissions: ['SHOW_MAINTENANCE'],
        // },
        {
          url: '/settings/report-tasks',
          text: 'Report Tasks',
          type: 0,
        },
        {
          url: '/settings/immobilizer',
          text: 'Immobilizer',
          type: 0,
          permissions: ['PROFILE_EDIT', 'SHOW_IMMOBILIZER'],
        },
        {
          url: '/bus-line',
          text: 'Bus',
          type: 1,
          permissions: ['SHOW_BUS'],
        },
      ],
    },
    {
      url: '/search',
      text: 'Search',
      type: 0,
    },
    {
      url: '/help',
      text: 'Support',
      type: -1,
      children: [
        {
          url: 'http://accugps.com/help',
          text: 'Get Help',
          type: 1,
        },
        {
          url: 'http://accugps.com/about/',
          text: 'About Us',
          type: 1,
        },
      ],
    },
  ],
  profileMenu: [
    {
      url: '/user-profile',
      text: 'User Profile',
      type: 0,
      icon: 'icon-setting',
    },
    {
      url: '/change-password',
      text: 'Change Password',
      type: 0,
      icon: 'icon-password',
      permissions: ['PROFILE_EDIT'],
    },
  ],
  footerLinks: [
    {
      url: 'http://accugps.com/home',
      text: 'Home Page',
    },
    {
      url: '#',
      text: 'Market Place',
      children: [
        {
            url: 'https://www.matrack.io/gpstracking/client_login.php?r=7985',
            text: 'ELD',
        },
        {
            url: 'https://www.digitaltrails.com',
            text: 'Routing Service',
        },
        {
            url: 'https://www.tracksolidpro.com/resource/dev/index.html#/login',
            text: 'Asset Tracking',
        },
        {
            url: 'https://fleet.roscolive.com/login',
            text: 'AI Video Telematics',
        }
    ]
    },
    {
      url: 'http://accugps.com/privacy',
      text: 'Privacy Policy',
    },
    {
      url: 'http://accugps.com/TOS',
      text: 'Terms of Service',
    },
  ],
  copyright: `Copyright © ${thisYear} Accugps All rights reserved.`,
  termsOfService: 'http://accugps.com/TOS',
}
