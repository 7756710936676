import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map, tap } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { Alert, AlertMeta, UnreadNum } from './alert.model'
import { Store } from '@ngxs/store'
import { SetUnreadNum } from '../session/session.actions'
import { decodeLatLng } from '../../util'

@Injectable()
export class AlertService {

    constructor(
        private http: HttpClient,
        private store: Store,
    ) {}

    loadUnreadNum() {
        return this.http.get(ApiAddress.UNREAD_NUM).pipe(
            map((res: NbResponse<UnreadNum>) => res.data.alert_cnt),
            tap(alertCnt => {
                this.store.dispatch(new SetUnreadNum(alertCnt))
            })
        )
    }

    loadAlerts(params) {
        return this.http.get(ApiAddress.ALERTS, { params }).pipe(
            map((res: NbResponse<Alert[], AlertMeta>) => (<[Alert[], AlertMeta]>[res.data, res.meta])),
            map(([alerts, meta]) => {
                alerts.map(alert => {
                    alert.latitude = decodeLatLng(alert.latitude)
                    alert.longitude = decodeLatLng(alert.longitude)
                })

                return <[Alert[], AlertMeta]>[alerts, meta]
            }),
        )
    }

    clearAllAlert() {
        return this.http.delete(ApiAddress.ALERTS).pipe(
            map((res: NbResponse<any>) => res.data),
        )
    }

    markAllAsRead() {
        return this.http.put(ApiAddress.ALERTS, {}).pipe(
            map((res: NbResponse<any>) => res.data),
        )
    }

    loadObdAlerts(params) {
        return this.http.get(ApiAddress.ALERTS_OBD, { params }).pipe(
            map((res: NbResponse<Alert[], AlertMeta>) => (<[Alert[], AlertMeta]>[res.data, res.meta])),
            map(([alerts, meta]) => {
                alerts.map(alert => {
                    alert.latitude = decodeLatLng(alert.latitude)
                    alert.longitude = decodeLatLng(alert.longitude)
                })

                return <[Alert[], AlertMeta]>[alerts, meta]
            }),
        )
    }

    clearObdAllAlert() {
        return this.http.delete(ApiAddress.ALERTS_OBD).pipe(
            map((res: NbResponse<any>) => res.data),
        )
    }

    markObdAllAsRead() {
        return this.http.put(ApiAddress.ALERTS_OBD, null).pipe(
            map((res: NbResponse<any>) => res.data),

        )
    }

    loadDtcAlerts(params) {
        return this.http.get(ApiAddress.ALERTS_DTC, { params }).pipe(
          map((res: NbResponse<Alert[], AlertMeta>) => (<[Alert[], AlertMeta]>[res.data, res.meta])),
          map(([alerts, meta]) => {
              alerts.map(alert => {
                  alert.latitude = decodeLatLng(alert.latitude)
                  alert.longitude = decodeLatLng(alert.longitude)
              })

              return <[Alert[], AlertMeta]>[alerts, meta]
          }),
        )
    }

    clearDtcAllAlert() {
        return this.http.delete(ApiAddress.ALERTS_DTC).pipe(
          map((res: NbResponse<any>) => res.data),
        )
    }

    markDtcAllAsRead() {
        return this.http.put(ApiAddress.ALERTS_DTC, null).pipe(
          map((res: NbResponse<any>) => res.data),
        )
    }

    loadCameraAlert(params) {
        return this.http.get(ApiAddress.ALERT_CAMERA_AI, {params}).pipe(
            map((res: NbResponse<Alert[], AlertMeta>) => (<[Alert[], AlertMeta]>[res.data, res.meta])),
            map(([alerts, meta]) => {
                alerts.map(alert => {
                    alert.latitude = decodeLatLng(alert.latitude)
                    alert.longitude = decodeLatLng(alert.longitude)
                })

                return <[Alert[], AlertMeta]>[alerts, meta]
            }),
        )
    }

    clearCameraAllAlert() {
        return this.http.delete(ApiAddress.ALERT_CAMERA_AI).pipe(
            map((res: NbResponse<any>) => res.data),
        )
    }

    markCameraAllAsRead() {
        return this.http.put(ApiAddress.ALERT_CAMERA_AI, null).pipe(
            map((res: NbResponse<any>) => res.data),
        )
    }
}
