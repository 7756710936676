import { State, Action, StateContext } from '@ngxs/store'
import { AccountAddTracker, EditShareAccount, ResetShareAccount } from './share-account.actions'
import { ShareAccountDetail, ShareAccountEmail } from './share-account.model'
import { Injectable } from '@angular/core'

export interface ShareAccount {
    accountData: ShareAccountEmail
    shareDetail: ShareAccountDetail
}

const initShareAccountState: ShareAccount = {
    accountData: null,
    shareDetail: null
}

@State<ShareAccount>({
    name: 'shareAccount',
    defaults: initShareAccountState
})
@Injectable()
export class ShareAccountState {
    constructor() {}

    @Action(AccountAddTracker)
    accountAddTracker(ctx: StateContext<ShareAccount>, action: AccountAddTracker) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            accountData: action.payload
        })
    }

    @Action(EditShareAccount)
    editShareAccount(ctx: StateContext<ShareAccount>, action: EditShareAccount) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            shareDetail: action.payload
        })
    }

    @Action(ResetShareAccount)
    resetShareAccount(ctx: StateContext<ShareAccount>) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
        })
    }
}

