import { CreatePoiData } from '../poi/poi.model'
import { CreateGeoFenceData } from '../geo-fence/geo-fence.model'

export class ShowTrackerInMap {
    static readonly type = '[Map] show tracker in map'
    constructor() {}
}

export class ShowPoiInMap {
    static readonly type = '[Map] show poi in map'
    constructor() {}
}

export class HideTrackersFromMap {
    static readonly type = '[Map] hide tracker from map'
    constructor() {}
}

export class HidePoisFromMap {
    static readonly type = '[Map] hide poi from map'
    constructor() {}
}

export class SaveCreatePoiData {
    static readonly type = '[Map] save create poi data'
    constructor(public payload: CreatePoiData) {}
}

export class RemoveCreatePoiData {
    static readonly type = '[Map] remove create poi data'
    constructor() {}
}

export class SaveCreateGeoFenceData {
    static readonly type = '[Map] save create geo-fence data'
    constructor(public payload: CreateGeoFenceData) {}
}

export class RemoveCreateGeoFenceData {
    static readonly type = '[Map] remove create geo-fence data'

    constructor() {
    }
}

export class MapZoomChanged {
    static readonly type = '[Map] map zoom changed'
    constructor(public payload: number) {}
}

export class CenterMap {
    static readonly type = '[Map] center the map'
    constructor(public payload: google.maps.LatLngLiteral) {}
}

export class MapLegendType {
    static readonly type = '[Map] save map legend type'
    constructor(public payload: string) {}
}
