import { Action, State, StateContext } from '@ngxs/store'
import { SetBuses, SetMapReady, UpdateBusPosition } from './bus-position.actions'
import { Tracker } from './bus-position.model'
import { Injectable } from '@angular/core'

export interface BusPosition {
    trackers: Tracker[],
    mapReady: boolean
}

const initBusPositionState: BusPosition = {
    trackers: null,
    mapReady: false
}

@State<BusPosition>({
    name: 'busPosition',
    defaults: initBusPositionState
})
@Injectable()
export class BusPositionState {
    constructor() {}

    @Action(SetBuses)
    setBuses(ctx: StateContext<BusPosition>, action: SetBuses) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            trackers: action.payload
        })
    }

    @Action(UpdateBusPosition)
    updateBusPosition(ctx: StateContext<BusPosition>, action: UpdateBusPosition) {
        const state = ctx.getState()
        const trackers = state.trackers
        const tracker = action.payload
        const index = trackers.findIndex(item => item.id === tracker.id)
        if (index < 0) {
            return new Error('[Update Tracker] no target tracker found!')
        }
        const newTracker = {...trackers[index], ...tracker}
        const newTrackers = [...trackers]
        newTrackers[index] = newTracker

        ctx.setState({
            ...state,
            trackers: newTrackers
        })
    }

    @Action(SetMapReady)
    setMapReady(ctx: StateContext<BusPosition>, action: SetMapReady) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            mapReady: action.payload
        })
    }
}
