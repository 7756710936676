import { Injectable } from '@angular/core'
import { ApiAddress, ApiV2Address } from '../../core.api'
import { map, tap } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { HttpClient } from '@angular/common/http'
import { Logout } from './authentication.store'
import { Store } from '@ngxs/store'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable()
export class AuthenticationService {

    constructor(
        private http: HttpClient,
        private store: Store,
        private router: Router,
    ) {
    }

    login(data) {
        return <Observable<NbResponse<any>>>this.http.post(ApiAddress.LOGIN, data)
    }

    tokenLogin(token) {
        return this.http.get(ApiAddress.LOGIN_TOKEN, {params: {token}})
    }

    logout() {
        return this.http.delete(ApiAddress.LOGOUT).pipe(tap(() => {
            this.router.navigate(['entrance']).then(() => {
                this.store.dispatch(new Logout())
            })
        }))
    }

    registerSendEmail(email) {
        return <Observable<NbResponse<any>>>this.http.get(ApiAddress.REGISTER, {params: {email}})
    }

    resgisterPassword(data) {
        return <Observable<NbResponse<any>>>this.http.post(ApiV2Address.REGISTER, data)
    }

    forgetSendEmail(email) {
        return <Observable<NbResponse<any>>>this.http.get(ApiAddress.RESETPASSWORD, {params: {email}})
    }

    forgetPassword(data) {
        return <Observable<NbResponse<any>>>this.http.put(ApiAddress.RESETPASSWORD, data)
    }

    modifyAccount(params): Observable<NbResponse<any>> {
        return this.http.get(ApiAddress.PROFILE_EMAIL, { params }).pipe(
            map((res: NbResponse<any>) => res)
        )
    }

    deleteUser(data) {
        return this.http.delete(ApiAddress.DELETE_USER,  { params: data })
    }

}
