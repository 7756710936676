import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/internal/operators'
import { ApiAddress } from '../../core.api'
import { NbResponse } from '../../models'
import { ReportTaskModel } from './report-task.model'

@Injectable()
export class ReportTaskService {

    constructor(
      private http: HttpClient,
    ) {
    }

    loadReportTasks() {
        return this.http.get(ApiAddress.REPORTS_TASKS).pipe(
          map((res: NbResponse<ReportTaskModel>) => res.data)
        )
    }

    createReportTasks(body) {
        return this.http.post(ApiAddress.REPORTS_TASKS, body).pipe(
          map((res: NbResponse<any>) => res.data)
        )
    }

    updateReportTasks(id, body) {
        return this.http.put(ApiAddress.REPORT_TASK, body, { params: { id } }).pipe(
          map((res: NbResponse<any>) => res.data)
        )
    }

    deleteReportTasks(id) {
        return this.http.delete(ApiAddress.REPORT_TASK, { params: { id } }).pipe(
          map((res: NbResponse<any>) => res.data)
        )
    }
}
