import { Injectable } from '@angular/core'
import {
    HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest,
    HttpResponse
} from '@angular/common/http'
import { Router } from '@angular/router'
import { NbResponse } from '../models'
import { catchError, map, throttleTime } from 'rxjs/operators'
import { Observable, Subject, throwError } from 'rxjs'
import { Store } from '@ngxs/store'
import { RequestDone } from '../states/loading/loading.store'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NB_LOADING_KEY } from './request.interceptor'

const shuntHandler = <T>(store, req: HttpRequest<any>) => (source: Observable<T>) => new Observable<T>((subscriber) => {
    source.subscribe({
        next(value: T) {
            if (value instanceof HttpResponse) {
                const body = value.body
                if (body.status === 200) {
                    subscriber.next(value)
                } else {
                    subscriber.error(body)
                }
            }
        },
        error(err) {
            // http error
            if (!req.headers.get(NB_LOADING_KEY)) {
                store.dispatch(new RequestDone())
            }
            subscriber.error({
                status: err.status,
                message: err.statusText
            })
        },
        complete() {
            if (!req.headers.get(NB_LOADING_KEY)) {
                store.dispatch(new RequestDone())
            }
            subscriber.complete()
        }
    })
})

@Injectable()
export class ResponseFormatInterceptor implements HttpInterceptor {
    private messageSubject$ = new Subject<string>()
    constructor(
        private router: Router,
        private store: Store,
        private messageService: NzMessageService,
    ) {
        this.messageSubject$.pipe(
            throttleTime(1000)
        ).subscribe(message => {
            this.messageService.error(message)
        })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpResponse<any>> {
        return next.handle(req).pipe(
            shuntHandler(this.store, req),
            map((httpResponse: HttpResponse<any>) => {
                const body: NbResponse<any> = httpResponse.body
                if (req.method !== 'GET' && !req.url.includes('v1/gv') && !req.url.includes('installer')) {
                    this.messageService.success(body.message)
                }

                return httpResponse
            }),
            catchError((err) => {
                if (err.status === 401) {
                    this.router.navigate(['entrance'])
                }
                this.messageSubject$.next(err.message)

                return throwError(err)
            }),
        )
    }
}

export const responseInterceptor = [
    // response 顺序是从后往前
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ResponseFormatInterceptor,
        multi: true
    }
]
