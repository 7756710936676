import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { ShareAccountEmail, ShareAccountDetail } from './share-account.model'

@Injectable()
export class ShareAccountService {

    constructor(
        private http: HttpClient,
    ) {}

    loadShareAccounts() {
        return this.http.get(ApiAddress.SHARE_ACCOUNTS_EMAILS).pipe(
            map((res: NbResponse<ShareAccountEmail[]>) => res.data),
        )
    }

    addShareAccount(body) {
        return this.http.post(ApiAddress.SHARE_ACCOUNTS_EMAILS, body).pipe(
            map((res: NbResponse<any>) => res),
        )
    }

    deleteShareAccounts(emails) {
        return this.http.request('delete', ApiAddress.SHARE_ACCOUNTS_EMAILS, {body: {emails}}).pipe(
            map((res: NbResponse<any>) => res.data)
        )
    }

    loadShareTrackerAccounts(id) {
        return this.http.get(ApiAddress.SHARE_ACCOUNTS_TRACKERS, {params: {id}}).pipe(
            map((res: NbResponse<ShareAccountDetail[]>) => res.data),
        )
    }

    loadShareAccountTrackers(email) {
        return this.http.get(ApiAddress.SHARE_ACCOUNTS_EMAIL, {params: {email}}).pipe(
            map((res: NbResponse<ShareAccountDetail[]>) => res.data),
        )
    }

    addTrackersToAccount(email, body) {
        return this.http.post(ApiAddress.SHARE_ACCOUNTS_EMAIL, body, {params: {email}}).pipe(
            map((res: NbResponse<any>) => res),
        )
    }

    modifyShareAccount(id, body) {
        return this.http.put(ApiAddress.SHARE_ACCOUNT, body, {params: {id}}).pipe(
            map((res: NbResponse<any>) => res.data),
        )
    }

    deleteShareAccount(id) {
        return this.http.delete(ApiAddress.SHARE_ACCOUNT, {params: {id}}).pipe(
            map((res: NbResponse<any>) => res.data),
        )
    }
}

