import { Component } from '@angular/core'

@Component({
  selector: 'app-loading',
  template: `<div class="loading-container"><div class="loader"></div></div>`,
  styleUrls: ['./loading.component.less']
})
export class LoadingComponent {
  constructor() { }
}
