import { ShareAccountDetail, ShareAccountEmail } from './share-account.model'

export class AccountAddTracker {
    static readonly type = '[Share Account] account add tracker'
    constructor(public payload: ShareAccountEmail) {}
}

export class EditShareAccount {
    static readonly type = '[Share Account] edit share account'
    constructor(public payload: ShareAccountDetail) {}
}

export class ResetShareAccount {
    static readonly type = '[Share Account] reset'
    constructor() {}
}

