import { State, Action, StateContext } from '@ngxs/store'
import { HideTracker, SetGoogleKey, ShowTracker } from './share-position.actions'
import { Injectable } from '@angular/core'

export interface SharePosition {
    activeTracker: string
    googleKey: string
}

const initSharePositionState: SharePosition = {
    activeTracker: '',
    googleKey: ''
}

@State<SharePosition>({
    name: 'sharePosition',
    defaults: initSharePositionState
})
@Injectable()
export class SharePositionState {
    constructor() {}

    @Action(ShowTracker)
    showTrackers(ctx: StateContext<SharePosition>, action: ShowTracker) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            activeTracker: action.payload
        })
    }

    @Action(HideTracker)
    hideTrackers(ctx: StateContext<SharePosition>) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            activeTracker: ''
        })
    }

    @Action(SetGoogleKey)
    setGoogleKey(ctx: StateContext<SharePosition>, action: SetGoogleKey) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            googleKey: action.payload
        })
    }
}

