import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map, tap } from 'rxjs/operators'
import { NORMAL_TERMINALS, TERMINAL_TYPES } from '../../core.constants'
import { NbResponse } from '../../models'
import { decodeLatLng } from '../../util'
import { Tracker } from './tracker.model'
import { Store } from '@ngxs/store'
import { SetTrackers } from '../session/session.actions'

import { Observable } from 'rxjs'

@Injectable()
export class TrackerService {

    constructor(
      private http: HttpClient,
      private store: Store,
    ) {
    }

    addDevice(body) {
        return <Observable<NbResponse<any>>>this.http.post(ApiAddress.TRACKERS, body)
    }

    loadTrackers() {
        return this.http.get(ApiAddress.TRACKERS, { params: {isLoading: 'false'}}).pipe(
          map((res: NbResponse<Tracker[]>) => res.data),
          map(trackers => {
              return trackers.map(tracker => {
                  return handleTracker(tracker)
              })
          }),
          tap(trackers => {
              this.store.dispatch(new SetTrackers(trackers))
          })
        )
    }

    loadTrackersFirst() {
        return this.http.get(ApiAddress.TRACKERS).pipe(
            map((res: NbResponse<Tracker[]>) => res.data),
            map(trackers => {
                return trackers.map(tracker => {
                    return handleTracker(tracker)
                })
            }),
            tap(trackers => {
                this.store.dispatch(new SetTrackers(trackers))
            })
        )
    }

    getTrackerById(id) {
        return this.http.get(ApiAddress.TRACKER, { params: { id } }).pipe(
          map((res: NbResponse<Tracker>) => res.data),
          map(handleTracker),
        )
    }

    tracingTracker(id) {
        return this.http.post(ApiAddress.TRACKER_TRACING, {}, { params: { id } })
    }

    saveTracker(id, value) {
        return this.http.put(ApiAddress.TRACKER, value, { params: { id } }).pipe(
          map((res: NbResponse<Tracker>) => res.data),
        )
    }

    changeOilCtrl(id, body) {
        return this.http.post(ApiAddress.TRACKER_OIL_CTRL, body, { params: { id } }).pipe(
          map((res: NbResponse<any>) => res.data),
        )
    }

    changeEmergency(id, body) {
        return this.http.put(ApiAddress.TRACKER_EMERGENCY, body, { params: { id } }).pipe(
          map((res: NbResponse<Tracker>) => res.data),
        )
    }

    getTrackerNextEmergency(id) {
        return this.http.get(ApiAddress.TRACKER_NEXT_EMERGENCY, { params: { id } }).pipe(
          map((res: NbResponse<{ next_time: number }>) => res.data),
        )
    }

    changeFleets(id, body) {
        return this.http.put(ApiAddress.TRACKER_FLEETS, body, { params: { id } }).pipe(
          map((res: NbResponse<any>) => res.data),
        )
    }

    changeTrackerNote(id, body) {
        return this.http.post(ApiAddress.TRACKERS_ID_NOTES, body, { params: { id } }).pipe(
          map((res: NbResponse<any>) => res.data),
        )
    }
}

export function handleTracker(tracker: Tracker) {
    return {
        ...tracker,
        latitude: decodeLatLng(tracker.latitude),
        longitude: decodeLatLng(tracker.longitude),
        emergencyAble: tracker.t_type === TERMINAL_TYPES.zj300,
        oilCtrlAble: tracker.t_type === TERMINAL_TYPES.zj700x,
        isOBD: tracker.t_type === TERMINAL_TYPES.obd,
        device_type: handleDeviceType(tracker.t_type)
    }
}

function handleDeviceType(type) {
    if (!type || (type.includes('ZJ') && type !== 'ZJ300')) {
        return 'normalTerminals'
    }
    if (type === 'CAMERA.AI') {
        return 'camera_ai'
    }
    if (type === 'CAMERA') {
        return 'camera'
    }
    if (type === 'OBD.SINO') {
        return 'obd'
    }
    if (type === 'ZJ300') {
        return '4GLocator'
    }
}
