export const modalOperation = {
    submit: 'submit',
    cancel: 'cancel',
}

export const TERMINAL_TYPES = {
    zj300: 'ZJ300',
    zj700x: 'ZJ700X',
    obd: 'OBD.SINO',
}

export const NORMAL_TERMINALS = [
    'ZJ100',
    'ZJ200',
    'ZJ220R',
    'ZJ210',
    'ZJ211',
    'ZJ700',
    'ZJ710',
    'ZJ700X',
    'ZJ700X',
]

export const StorageKeys = {
    activedTracker: 'ACTIVE_TRACKER',
}

export const ALL_DIRECTION = [
    'North',
    'Northeast',
    'East',
    'Southeast',
    'South',
    'Southwest',
    'West',
    'Northwest',
]

export const ALL_DEGREE = [0, 45, 90, 135, 180, 225, 270, 315, 360]

export const timeFormats = ['hh:mm a', 'HH:mm']
export const dateFormats = ['DD/MM/YYYY', 'MM/DD/YYYY']
export const esDateFormats = ['DD MMM YYYY', 'MMM DD YYYY']

export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]

export const weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const wholeDay =
    '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23'

export const RoleAssignment = [
    'Guest',
    'Administrator',
    'Group Manager',
    'Driver',
]

export const profileQuestions = [
    'What was your childhood nickname?',
    'What was the name of your elementary / primary school?',
    'What is the name of the company of your first job?',
    'What is the name of your favorite childhood friend?',
    'What was your favorite place to visit as a child?',
    'What is the name of your favorite childhood teacher?',
    'What is your preferred musical genre?',
    'In what city and country do you want to retire? ',
    'What was your high school mascot?',
    'What year did you graduate from High School?',
    'Others',
]

export const AlertTypeItems = [
    { id: -1, name: 'All' },
    { id: 2, name: 'Battery low' },
    { id: 10, name: 'Battery full' },
    { id: 7, name: 'Entering geofence' },
    { id: 8, name: 'Exiting geofence' },
    { id: 3, name: 'Disturbance' },
    { id: 4, name: 'Movement' },
    { id: 5, name: 'SOS' },
    { id: 11, name: 'Speeding' },
    { id: 12, name: 'Stopped' },
    { id: 13, name: 'Arriving POI' },
    { id: 14, name: 'Leaving POI' },
    { id: 6, name: 'Communication interrupted' },
    { id: 16, name: 'Tracker lost power connection' },
    { id: 17, name: 'Maintenance' },
    { id: 21, name: 'Emergency mode' },
    { id: 22, name: 'Idle' },
    { id: 23, name: 'Over Driving' },
    { id: 24, name: 'Rapid Acceleration' },
    { id: 25, name: 'Harsh Braking' },
    { id: 26, name: 'Over Local Speed' },
    { id: 27, name: 'ACC is abnormal' },
    { id: 28, name: 'Voltage low' },
    { id: 29, name: 'Illegal car use' },
    { id: 30, name: 'Long Stop' },
    { id: 31, name: 'Collision' },
    { id: 32, name: 'Driver behavior' },
    { id: 33, name: 'Battery Voltage Low' },
    { id: 34, name: 'GPS Malfunction' },
    { id: 35, name: 'GPS Disconnected' },
    { id: 36, name: 'GPS Short' },
    { id: 37, name: 'Camera Malfunction' },
]

export const ObdAlertTypeItems = [
    { id: -1, name: 'All' },
    { id: 50, name: 'Speeding (OBD)' },
    { id: 51, name: 'Low voltage (OBD)' },
    { id: 52, name: 'High engine coolant temperature (OBD)' },
    { id: 53, name: 'Hard acceleration (OBD)' },
    { id: 54, name: 'Hard deceleration (OBD)' },
    { id: 55, name: 'Idle engine (OBD)' },
    { id: 56, name: 'Towing (OBD)' },
    { id: 57, name: 'High RPM (OBD)' },
    { id: 58, name: 'Power on alarm (OBD)' },
    { id: 59, name: 'Exhaust Emission (OBD)' },
    { id: 60, name: 'Quick Lane change (OBD)' },
    { id: 61, name: 'Sharp turn (OBD)' },
    { id: 62, name: 'Fatigue driving (OBD)' },
    { id: 63, name: 'Power off alarm (OBD)' },
    { id: 64, name: 'Geofence alarm (OBD)' },
    { id: 65, name: 'Emergency alarm (OBD)' },
    { id: 66, name: 'Crash alarm (OBD)' },
    { id: 67, name: 'Tamper (OBD)' },
    { id: 68, name: 'Illegal enter (OBD)' },
    { id: 69, name: 'Illegal ignition (OBD)' },
    { id: 70, name: 'OBD communication error (OBD)' },
    { id: 71, name: 'Ignition on (OBD)' },
    { id: 72, name: 'Ignition off (OBD)' },
    { id: 73, name: 'MIL alam (OBD)' },
    { id: 74, name: 'Unlock alarm (OBD)' },
    { id: 75, name: 'No card presented (OBD)' },
    { id: 76, name: 'Dangerous driving (OBD)' },
    { id: 77, name: 'Vibration alarm (OBD)' },
]

export const bsjAlertTypeItems = [
    { id: -1, name: 'All' },
    { id: 91, name: 'Lane departure warning' },
    { id: 92, name: 'Distance closer warning' },
    { id: 93, name: 'Forward Collision warning' },
    { id: 94, name: 'Fatigue Driving Alarm' },
    { id: 95, name: 'Smoking Alarm' },
    { id: 96, name: 'Distraction Alarm' },
    { id: 99, name: 'Driver Abnormal Alarm' },
    { id: 100, name: 'Phone Call Alarm' },
    { id: 102, name: 'Signal Occlusion' },
    { id: 103, name: 'Storage Malfunction' },
]

export const alertTypeArr = [
    '',
    '',
    ['Battery depleted', 'Battery low'],
    'Disturbance',
    'Movement',
    'SOS',
    'Communication interrupted',
    'Entering geofence',
    'Exiting geofence',
    '',
    'Battery full',
    'Speeding',
    'Stopped',
    'Arriving POI',
    'Leaving POI',
    '',
    'Tracker lost power connection',
    'Maintenance',
    '',
    '',
    '',
    'Emergency mode',
    'Idle',
    'Over Driving',
    'Rapid Acceleration',
    'Harsh Braking',
    'Over Local Speed',
    'ACC is abnormal',
    'Voltage low',
    'Illegal car use',
    'Long Stop',
    'Collision',
    'Driver Behavior',
    'Battery Voltage Low',
    'GPS Malfunction',
    'GPS Disconnected',
    'GPS Short',
    'Camera Malfunction',
]

export const obdAlertTypeArr = [
    'Speeding (OBD)',
    'Low voltage (OBD)',
    'High engine coolant temperature (OBD)',
    'Hard acceleration (OBD)',
    'Hard deceleration (OBD)',
    'Idle engine (OBD)',
    'Towing (OBD)',
    'High RPM (OBD)',
    'Power on alarm (OBD)',
    'Exhaust Emission (OBD)',
    'Quick Lane change (OBD)',
    'Sharp turn (OBD)',
    'Fatigue driving (OBD)',
    'Power off alarm (OBD)',
    'Geofence alarm (OBD)',
    'Emergency alarm (OBD)',
    'Crash alarm (OBD)',
    'Tamper (OBD)',
    'Illegal enter (OBD)',
    'Illegal ignition (OBD)',
    'OBD communication error (OBD)',
    'Ignition on (OBD)',
    'Ignition off (OBD)',
    'MIL alam (OBD)',
    'Unlock alarm (OBD)',
    'No card presented (OBD)',
    'Dangerous driving (OBD)',
    'Vibration alarm (OBD)',
]

export const bsjAlertType = [
    'Lane departure warning',
    'Distance closer warning',
    'Forward Collision warning',
    'Fatigue Driving Alarm',
    'Smoking Alarm',
    'Distraction Alarm',
    '',
    '',
    'Driver Abnormal Alarm',
    'Phone Call Alarm',
    '',
    'Signal Occlusion',
    'Storage Malfunction',
]

export const reportsType = [
    { id: 1, name: 'Summary' },
    { id: 2, name: 'Travel' },
    { id: 3, name: 'Maintenance' },
    { id: 4, name: 'IFTA (State Miles) ' },
]

export const timesType = [
    { id: 1, name: 'Every day' },
    { id: 2, name: 'Weekly' },
    { id: 3, name: 'Monthly' },
]

export const Events = {
    1: 'Other',
    2: 'Harsh Brake',
    3: 'Rapid Acceleration',
    4: 'Speeding',
    5: 'Seatbelt Violation',
    6: 'Crash (Accident)',
}

export const NotificationGroups = [
    // normalTerminals
    {
        name: 'Battery Low',
        value: 'powerlow',
        colSpan: 1,
        types: ['normalTerminals'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Battery Full',
        value: 'powerfull',
        colSpan: 1,
        types: ['normalTerminals'],
        className: 'label-text-right',
        isTd: true,
    },
    {
        name: 'Disturbance',
        value: 'illegalshake',
        colSpan: 1,
        types: ['normalTerminals'],
        className: 'label-text-right',
        isTd: true,
    },
    {
        name: 'Tracker lost power connection',
        value: 'usb_disconnect',
        colSpan: 2,
        types: ['normalTerminals'],
        className: 'label-text-left',
        isTd: true,
    },
    // 所有类型都支持
    {
        name: 'Communication Lost',
        value: 'heartbeat_lost',
        colSpan: 1,
        types: ['normalTerminals', '4GLocator', 'camera', 'obd', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Geo Fence',
        value: 'region',
        colSpan: 1,
        types: ['normalTerminals', '4GLocator', 'camera', 'obd', 'camera_ai'],
        className: 'label-text-right',
        isTd: true,
    },
    {
        name: 'Stopped',
        value: 'stopping',
        colSpan: 1,
        types: ['normalTerminals', '4GLocator', 'camera', 'obd', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Movement',
        value: 'illegalmove',
        colSpan: 1,
        types: ['normalTerminals', '4GLocator', 'camera', 'obd', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Speeding Over',
        value: 'speed_limit',
        colSpan: 1,
        types: ['normalTerminals', '4GLocator', 'camera', 'obd', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
        switchValue: 'overspeed',
    },
    {
        name: 'Idle',
        value: 'hos_idle_time',
        colSpan: 1,
        types: ['normalTerminals', '4GLocator', 'camera', 'obd', 'camera_ai'],
        className: 'label-text-right',
        min: 1,
        max: 100,
        isTd: true,
        switchValue: 'hos_idle',
    },
    // 支持camera
    {
        name: 'Collision',
        value: 'collision',
        colSpan: 1,
        types: ['camera', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    // {
    //     name: 'Driver Behavior',
    //     value: 'driver_behavior',
    //     colSpan: 1,
    //     types: ['camera'],
    //     className: 'label-text-right',
    //     isTd: true,
    // },
    {
        name: 'Battery Voltage Low',
        value: 'battery_voltage_low',
        colSpan: 1,
        types: ['camera', 'camera_ai'],
        className: 'label-text-right',
        isTd: true,
    },

    // 支持 obd
    {
        name: 'Over Driving',
        value: 'hos_driving',
        colSpan: 2,
        types: ['obd'],
        className: 'label-text-left',
        isTd: false,
    },
    {
        name: 'High RPM',
        value: 'high_rpm',
        colSpan: 1,
        types: ['obd'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Voltage Low',
        value: 'low_voltage',
        colSpan: 1,
        types: ['obd'],
        className: 'label-text-right',
        isTd: true,
    },

    {
        name: 'High Engine Coolant Temperature',
        value: 'high_engine_coolant_temperature',
        colSpan: 2,
        types: ['obd'],
        className: 'label-text-left',
        isTd: false,
    },
    {
        name: 'Power On Alarm',
        value: 'power_on_alarm',
        colSpan: 1,
        types: ['obd'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Power Off Alarm',
        value: 'power_off_alarm',
        colSpan: 1,
        types: ['obd'],
        className: 'label-text-right',
        isTd: true,
    },
    {
        name: 'Rapid Acceleration',
        value: 'rapid_acceleration',
        colSpan: 1,
        types: ['normalTerminals', '4GLocator', 'camera', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Hash Breaking',
        value: 'harsh_braking',
        colSpan: 1,
        types: ['normalTerminals', '4GLocator', 'camera', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Long Stop Time',
        value: 'long_stop_time',
        colSpan: 2,
        types: ['normalTerminals', '4GLocator', 'camera', 'obd', 'camera_ai'],
        className: 'label-text-right',
        isTd: false,
        min: 3,
        max: 1440,
        switchValue: 'long_stop',
    },
    {
        name: 'Lane Departure Warning',
        value: 'lane_departure_warning',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Distance Closer Warning',
        value: 'distance_closer_warning',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-right',
        isTd: true,
    },
    {
        name: 'Forward Collision Warning',
        value: 'forward_collision_warning',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-right',
        isTd: true,
    },
    {
        name: 'Fatigue Driving Alarm',
        value: 'fatigue_driving_alarm',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Smoking Alarm',
        value: 'smoking_alarm',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-right',
        isTd: true,
    },
    {
        name: 'Distraction Alarm',
        value: 'distraction_alarm',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Driver Abnormal Alarm',
        value: 'driver_abnormal_alarm',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Phone Call Alarm',
        value: 'phone_call_alarm',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-right',
        isTd: true,
    },
    // 新增
    {
        name: 'Signal Occlusion',
        value: 'signal_occlusion',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Storage Malfunction',
        value: 'storage_malfunction',
        colSpan: 1,
        types: ['camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'GPS Malfunction',
        value: 'gnss_malfunction',
        colSpan: 1,
        types: ['camera', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'GPS Disconnected',
        value: 'gnss_disconnected',
        colSpan: 1,
        types: ['camera', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'GPS Short',
        value: 'gnss_short',
        colSpan: 1,
        types: ['camera', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
    {
        name: 'Camera Malfunction',
        value: 'camera_malfunction',
        colSpan: 1,
        types: ['camera', 'camera_ai'],
        className: 'label-text-left',
        isTd: true,
    },
]

export const driverEvents = [
    { id: 11, name: 'Speeding' },
    { id: 25, name: 'Harsh Braking' },
    { id: 24, name: 'Rapid Acceleration' },
    { id: 91, name: 'Lane departure warning' },
    { id: 92, name: 'Distance closer warning' },
    { id: 93, name: 'Forward Collision warning' },
    { id: 94, name: 'Fatigue Driving Alarm' },
    { id: 95, name: 'Smoking Alarm' },
    { id: 96, name: 'Distraction Alarm' },
    { id: 99, name: 'Driver Abnormal Alarm' },
    { id: 100, name: 'Phone Call Alarm' },
]

export const MaintenanceTypes = [
    '180 day inspection',
    '90 day inspection',
    'AC & Heater',
    'Axles',
    'Battery',
    'Brakes',
    'Engine',
    'Filters',
    'Lease Expiry',
    'License Expiry',
    'Electrical',
    'Oil Change',
    'Registration Expiry',
    'Spark Plugs',
    'Tires',
    'Brake connections',
    'Breakaway Battery',
    'Coupling Devices',
    'Coupling Pin',
    'DOT Annual inspection',
    'Doors',
    'Grease',
    'Lights',
    'Reflectors',
    'Roof',
    'Seals',
    'Springs',
    'Suspension System',
    'Wheels & Rims',
]
