import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngxs/store'
import { NzIconService } from 'ng-zorro-antd/icon'

@Component({
  selector: 'app-root',
  template: `<app-loading *ngIf="httpCount > 0"></app-loading>
  <browser-update></browser-update>
  <router-outlet></router-outlet>`,
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
    httpCount
    httpCountSub

    constructor(
        private store: Store,
        private cdf: ChangeDetectorRef,
        private iconService: NzIconService
    ) {
        this.iconService.fetchFromIconfont({
            scriptUrl: 'https://at.alicdn.com/t/c/font_97509_cwaw29ht85.js'
        })
    }

    ngOnInit() {
        this.httpCountSub = this.store.select(state => state.loading.reqCount)
            .subscribe(httpCount => {
                this.httpCount = httpCount
                this.cdf.detectChanges()
            })
    }

    ngOnDestroy() {
        this.httpCountSub.unsubscribe()
    }
}
