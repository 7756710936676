import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PageNotFoundComponent } from '../../../shared/components/page-not-found/page-not-found.component'
import { VideoNotFoundComponent } from '../../../shared/components/video-not-found/video-not-found.component'

const routes: Routes = [
    {
        path: 'video-404',
        component: VideoNotFoundComponent
    },
    {
        path: '404',
        component: PageNotFoundComponent
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full'
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
    constructor() {}
}
