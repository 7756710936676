import { ImmediatePhoto } from '../fleet/fleet.model'

export class SetPlayVideoTrackerId {
    static readonly type = '[Live Video] set play video tracker id'
    constructor(public payload: string) {}
}

export class ShowLiveVideo {
    static readonly type = '[Live Video] toggle map view live video'

    constructor(public payload: boolean) {
    }
}

export class CacheImmediatePhoto {
    static readonly type = '[Live Video] cache new immediate photo info'

    constructor(public payload: ImmediatePhoto) {
    }
}

export class ResetLiveVideoState {
    static readonly type = '[Live Video] logout and reset live video state'

    constructor() {
    }
}
