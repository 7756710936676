import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { Announcement } from './announcement.model'

@Injectable()
export class AnnouncementService {

    constructor(
        private http: HttpClient,
    ) {}

    loadAnnouncement() {
        return this.http.get(ApiAddress.ANNOUNCEMENTS_LASTEST).pipe(
            map((res: NbResponse<Announcement>) => res.data),
        )
    }
}
