const apiBase = 'api'
const apiVersion = 'v1'
const ApiVersion2 = 'v2'

export const ApiAddress = {
    GOOGLE_KEY: 'google_key',
    GV: 'gv',
    LOGIN: 'login',
    LOGIN_TOKEN: 'login/:token',
    LOGOUT: 'logout',
    REGISTER: 'register',
    PASSWORD: 'password',
    RESETPASSWORD: 'reset/password',
    PROFILE_EMAIL: 'profile/email',
    DELETE_USER: 'accounts/delete',
    WS_PUSH: 'wspush',
    AVATAR: 'avatar',
    PROFILE: 'profile',
    FIRST_LOGIN: 'first_login',
    TRACKERS: 'trackers',
    TRACKER: 'trackers/:id',
    TRACKER_FLEETS: 'trackers/:id/fleets',
    TRACKER_OIL_CTRL: 'trackers/:id/oil_control',
    TRACKER_EMERGENCY: 'trackers/:id/emergency',
    TRACKER_NEXT_EMERGENCY: 'trackers/:id/next_emergency',
    TRACKER_TRACING: 'trackers/:id/tracing',
    PRIVILEGES: 'privileges',
    ALERTS: 'alerts',
    ALERTS_OBD: 'alerts/obd',
    ALERTS_DTC: 'alerts/dtc',
    ALERT_CAMERA_AI: 'alerts/ai',
    DOWNLOAD_EVENTS: 'download/events',
    DOWNLOAD_OBD_EVENTS: 'download/obd',
    DOWNLOAD_DTC_EVENTS: 'download/dtc',
    UNREAD_NUM: 'alerts/unreadnum',
    POIS: 'pois',
    POI: 'pois/:id',
    COUNTRIES: 'countries',
    PROVINCES: 'countries/:id/provinces',
    NOTIFICATIONS: 'trackers/:id/notifications',
    PROFILEEMAIL: 'profile/email',
    REPORTS_SUMMARIES: 'reports/summaries',
    DOWNLOAD_SUMMARIES: 'download/summaries',
    REGIONS: 'regions',
    REGIONSID: 'regions/:id',
    REPORTS_TRAVEL: 'reports/travel',
    DOWNLOAD_TRAVEL: 'download/travel',
    REPORTS_MILEAGE: 'reports/mileage',
    DOWNLOAD_MILEAGE: 'download/mileage',
    REPORTS_DRIVINGTIME_TOTAL: 'reports/drivingtime/total',
    REPORTS_DRIVINGTIME_DETAIL: 'reports/drivingtime/detail',
    DOWNLOAD_DRIVINGTIME_TOTAL: 'download/drivingtime/total',
    DOWNLOAD_DRIVINGTIME_DETAIL: 'download/drivingtime/detail',
    REPORTS_TEMPERATURE: 'reports/temperature',
    DOWNLOAD_TEMPERATURE: 'download/temperature',
    REPORTS_SPEEDVIOLATION_PIE: 'reports/speedviolation/pie',
    REPORTS_SPEEDVIOLATION_TOTAL: 'reports/speedviolation/total',
    REPORTS_SPEEDVIOLATION_DETAIL: 'reports/speedviolation/detail',
    DOWNLOAD_SPEEDVIOLATION_PIE: 'download/speedviolation/pie',
    DOWNLOAD_SPEEDVIOLATION_TOTAL: 'download/speedviolation/total',
    DOWNLOAD_SPEEDVIOLATION_DETAIL: 'download/speedviolation/detail',
    REPORTS_SPEED_AVERSPEED: 'reports/speed/averspeed',
    REPORTS_SPEED_PIE: 'reports/speed/pie',
    DOWNLOAD_SPEED_AVERSPEED: 'download/speed/averspeed',
    DOWNLOAD_SPEED_PIE: 'download/speed/pie',
    REPORTS_FUEL: 'reports/fuel',
    DOWNLOAD_FUEL: 'download/fuel',
    REPORTS_MAINTENANCE: 'reports/maintenance',
    REPORTS_ORANGEWIRE: 'reports/poweron',
    DOWNLOAD_ORANGEWIRE: 'download/poweron',
    REPORTS_ELOG: 'reports/elog',
    DOWNLOAD_ELOG: 'download/elog',
    REPORTS_OIL_CONTROL: 'reports/oil-control',
    DOWNLOAD_OIL_CONTROL: 'download/oil-control',
    STATES: 'states',
    REPORTS_IFTA: 'reports/statemileage',
    DOWNLOAD_IFTA: 'download/statemileage',
    REPORTS_GEOFENCEVISIT: 'reports/region/visit',
    DOWNLOAD_GEOFENCEVISIT: 'download/region/visit',
    IFTACALCULATETIME: 'reports/ifta/quarter',
    IFTACALCULAT: 'reports/ifta',
    DOWNLOAD_IFTACALCULATE: 'download/ifta',
    IFTAFUELSTATE: 'refuel/record',
    DOWNLOAD_IFTAFUELSTATE: 'download/refuel/record',
    DOWNLOAD_MAINTENANCE: 'download/maintenance',
    MAINTENANCES: 'maintenances',
    MAINTENANCE_SERVICES: 'maintenances/:id/services',
    MAINTENANCE_SERVICE: 'maintenances/:id/services/:sid',
    MIANTENANCE_HISTORY: 'maintenances/:id/history/:hid',
    MAINTENANCE_SERVICE_RESET: 'maintenances/:id/services/:sid/reset',
    MAINTENANCE_REPORT: 'maintenances/log',
    MAINTENANCE_LOG: 'maintenances/log/:id',
    MAINTENANCESID: 'maintenances/:id',
    SERVICETYPE: 'servicetype',
    SERVICETYPEID: 'servicetype/:id',
    VEHICLES: 'vehicles',
    VEHICLESID: 'vehicles/:id',
    REPORTS_WORKHOUR: 'reports/workhour',
    DOWNLOAD_WORKHOUR: 'download/workhour',
    REPORTS_ROUTE: 'reports/route',
    DOWNLOAD_SHARE_ACCOUNTS: 'download/share_accounts',
    SHARE_ACCOUNT: 'share_accounts/:id',
    SHARE_ACCOUNTS_EMAILS: 'share_accounts/emails',
    SHARE_ACCOUNTS_EMAIL: 'share_accounts/emails/:email',
    SHARE_ACCOUNTS_TRACKERS: 'share_accounts/trackers/:id',
    TRACK: 'track/:tid',
    TRACK_LOCATIONS: 'track/:tid/locations',
    TRACK_EVENTS: 'track/events',
    DOWNLOAD_STOPPING: 'download/stopping',
    DOWNLOAD_POSITION: 'download/position',
    FLEETS: 'fleets',
    FLEET: 'fleets/:id',
    SHARE_POSITION: 'trackers/:id/token',
    SHARE_TRACKER_POSITION: 'trackers/position/:token',
    ANNOUNCEMENTS_LASTEST: 'announcements/lastest',
    BUS_POSITION: 'bus_geofence/:geoFenceId/token',
    BUS_POSITION_GEOFENCE: 'bus_geofence/:token',
    BUS_POSITION_TRACKERS: 'bus_geofence/:token/trackers',
    BUS_POSITION_POIS: 'bus_poi/:token',
    BUS_POSITION_PUSH: 'bus_push/:token',

    // driver management
    DRIVERS: 'drivers',
    DRIVERS_ID: 'drivers/:id',
    DRIVERS_VEHICLES: 'drivers/vehicles',
    DRIVERS_VEHICLES_ID: 'drivers/vehicles/:id',
    DRIVERS_VEHICLES_UNALLOCATED: 'drivers/trackers/unallocated',
    DEPARTMENTS_TRACKERS_UNALLOCATED: 'departments/trackers/unallocated',
    // users manager {groups/drivers}
    // ---groups
    DEPARTMENTS: 'departments',
    DEPARTMENTS_ID: 'departments/:id',

    TRACKERS_ID_NOTES: 'trackers/:id/notes',
    TRACKERS_PUNCH: 'trackers/:id/punch',
    REPORT_DTC: 'reports/dtc',
    DOWNLOAD_REPORT_DTC: 'download/reports/dtc',
    TRACKER_LIVE_VIDEO: 'trackers/:id/live-video',
    PHOTO_INSTRUCTIONS: 'photos/instructions',
    PHOTO_INTERVAL: 'photos-interval',
    PHOTO_INTERVAL_ID: 'photos-interval/:id',
    TRACKER_VIDEOS: 'trackers/:tid/videos',
    PLAY_VIDEO: 'trackers/:tid/video-playback',
    TASKS: 'trackers/:tid/videos/tasks',
    TASK: 'trackers/:tid/videos/tasks/:id',
    ELD_EVENTS: 'trackers/:tid/eld-events',
    // 司机评分
    DRIVE_OVERVIEW: 'driver_score',
    DRIVER_SCORE_SETTING: 'driver_score/setting',
    DRIVER_SCORE_DRIVERS: 'driver_score/drivers',
    DRIVER_SCORE_EVENTS: 'driver_score/events',
    DOWNLOAD_SCORE: 'driver_score/download',
    DRIVERS_NAME: 'drivers',

    SIMPLE_REGIONS: 'regions/simple',
    SIMPLE_POIS: 'pois/simple',
    // 报表
    REPORTS_TASKS: 'reports/tasks',
    REPORT_TASK: 'reports/tasks/:id',
    //  修改单个围栏信息
    SIMPLE_FENCE: 'regions/:id/simple',
    SIMPLE_POI: 'pois/:id/simple',
    DOWNLOAD_MAINTENANCE_HISTORY: 'download/maintenances/log',
    DOWNLOAD_CAMERA_AI_EVENTS: 'download/ai',
    // immobilizer
    IMMOBILIZER_RULES: 'immobilizer/schedules',
    IMMOBILIZER_RULE: 'immobilizer/schedules/:id',
    IMMOBILIZER_RULE_DETAIL: 'immobilizer/schedules/detail/:id',
    IMMOBILIZER_TRACKERS: 'immobilizer/trackers',
    IMMOBILIZER_TRACKER_DETAIL: 'immobilizer/trackers/detail/:id',
    IMMOBILIZER_TRACKER: 'immobilizer/trackers/:id',
    // 双向对讲
    TRACKER_LIVE_TALK: 'trackers/:id/live-talk'
}

export const ApiV2Address = {
    TIMEZONES: 'timezones',
    REGISTER: 'register',
    PROFILE: 'profile'
}

Object.keys(ApiAddress).forEach((key) => {
    ApiAddress[key] = `${apiBase}/${apiVersion}/${ApiAddress[key]}`
})

Object.keys(ApiV2Address).forEach((key) => {
    ApiV2Address[key] = `${apiBase}/${ApiVersion2}/${ApiV2Address[key]}`
})
