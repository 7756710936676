import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { Privileges } from './privileges.model'

@Injectable()
export class PrivilegesService {

    constructor(
        private http: HttpClient,
    ) {}

    loadPrivileges() {
        return this.http.get(ApiAddress.PRIVILEGES).pipe(
            map((res: NbResponse<Privileges>) => res.data),

        )
    }
}
