import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map, tap } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { Store } from '@ngxs/store'
import { SetGeoFences } from '../session/session.actions'
import { BusToken, GeoFence, GeoFenceMeta } from './geo-fence.model'
import { decodeLatLng, encodeLatLng } from '../../util'

@Injectable()
export class GeoFenceService {

    constructor(
      private http: HttpClient,
      private store: Store,
    ) {
    }

    loadGeoFences(page) {
        return this.http.get(ApiAddress.REGIONS, { params: { page } }).pipe(
          map((res: NbResponse<GeoFence[], GeoFenceMeta>) => (
            <[GeoFence[], GeoFenceMeta]>[res.data.map(changeGeoFence), res.meta])
          ),
          tap(([data]) => {
              this.store.dispatch(new SetGeoFences(data))
          })
        )
    }

    addGeoFence(value) {
        if (value.circle) {
            value.circle.latitude = encodeLatLng(value.circle.latitude)
            value.circle.longitude = encodeLatLng(value.circle.longitude)
        }
        if (value.polygon) {
            value.polygon = value.polygon.map(item => {
                item.latitude = encodeLatLng(item.latitude)
                item.longitude = encodeLatLng(item.longitude)

                return item
            })
            value.shape = 1
        }

        return this.http.post(ApiAddress.REGIONS, value).pipe(
          map((res: NbResponse<any>) => res.data))
    }

    editGeoFence(id, value) {
        if (value.circle) {
            value.circle.latitude = encodeLatLng(value.circle.latitude)
            value.circle.longitude = encodeLatLng(value.circle.longitude)
        }
        if (value.polygon) {
            value.polygon = value.polygon.map(item => {
                item.latitude = encodeLatLng(item.latitude)
                item.longitude = encodeLatLng(item.longitude)

                return item
            })
        }

        return this.http.put(ApiAddress.REGIONSID, value, { params: { id } }).pipe(
          map((res: NbResponse<GeoFence[]>) => res.data))
    }

    deleteGeoFence(id) {
        return this.http.delete(ApiAddress.REGIONSID, { params: { id } }).pipe(
          map((res: NbResponse<GeoFence[]>) => res.data))
    }

    getGeoFence(id) {
        return this.http.get(ApiAddress.REGIONSID, { params: { id } }).pipe(
          map((res: NbResponse<GeoFence>) => changeGeoFence(res.data)))
    }

    simpleGeofence(id, body) {
        return this.http.put(ApiAddress.SIMPLE_FENCE, body, { params: { id } }).pipe(
          map((res: NbResponse<GeoFence[]>) => res.data))
    }

    getBusToken(geoFenceId) {
        return this.http.get(ApiAddress.BUS_POSITION, { params: { geoFenceId } }).pipe(
          map((res: NbResponse<BusToken>) => res.data)
        )
    }
}

function changeGeoFence(geoFence) {
    if (geoFence.circle) {
        geoFence.circle.latitude = decodeLatLng(geoFence.circle.latitude)
        geoFence.circle.longitude = decodeLatLng(geoFence.circle.longitude)
    }

    if (geoFence.polygon) {
        geoFence.polygon = geoFence.polygon.map(point => {
            point.latitude = decodeLatLng(point.latitude)
            point.longitude = decodeLatLng(point.longitude)

            return point
        })
    }

    return geoFence
}
