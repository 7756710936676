import { BrowserModule } from '@angular/platform-browser'
import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserUpdateComponent } from '../../../shared/components/browser-update/browser-update.component'
import { LoadingComponent } from '../../../shared/components/loading/loading.component'
import { AppComponent } from './app.component'
import { registerLocaleData } from '@angular/common'
import en from '@angular/common/locales/zh'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n'
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { CoreModule } from '../../../core/core.module'
import { AppRoutingModule } from './app-routing.module'
import { PagesModule } from './pages/pages.module'
import {
    APP_NAME,
    MAP_CENTER
} from '../../../core/service/cross-platform.service'
import {
    LAZY_MAPS_API_CONFIG,
    LazyMapLoaderConfig
} from '../../../libs/gmap/services/lazy-loader.service'
// import { Recorder } from 'recorder-core'

registerLocaleData(en)

const nzConfig: NzConfig = {
    message: {
        nzDuration: 4000,
        nzMaxStack: 4,
        nzPauseOnHover: true,
        nzAnimate: true,
        nzTop: 50
    }
}

const MapConfig: LazyMapLoaderConfig = {
    apiVersion: '3.35',
    language: 'en_US',
    libraries: ['drawing', 'places', 'geometry']
}

const mapCenter = {
    lat: 38.5,
    lng: -97
}

@NgModule({
    declarations: [AppComponent, LoadingComponent, BrowserUpdateComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        PagesModule,
        NzMessageModule,
        AppRoutingModule,
        CoreModule
    ],
    providers: [
        { provide: NZ_CONFIG, useValue: nzConfig },
        { provide: LOCALE_ID, useValue: 'en' },
        { provide: NZ_I18N, useValue: en_US },
        { provide: APP_NAME, useValue: 'accugps' },
        { provide: LAZY_MAPS_API_CONFIG, useValue: MapConfig },
        { provide: MAP_CENTER, useValue: mapCenter }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
