import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { ApiAddress } from '../../core.api'
import { NbResponse } from '../../models'
import { decodeLatLng } from '../../util'

@Injectable()
export class SharePositionService {

    constructor(
      private http: HttpClient,
    ) {
    }

    getTrackerToken(id) {
        return this.http.get(ApiAddress.SHARE_POSITION, {params: {id}}).pipe(
          map((res: NbResponse<any>) => res.data)
        )
    }

    getTrackerInfoByToken(token) {
        return this.http.get(ApiAddress.SHARE_TRACKER_POSITION, {params: {token}}).pipe(
          map((res: NbResponse<any>) => res.data),
          map(data => {
              data.trackers.map(tracker => {
                  tracker.latitude = decodeLatLng(tracker.latitude)
                  tracker.longitude = decodeLatLng(tracker.longitude)
              })

              return data
          })
        )
    }
}
