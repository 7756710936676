import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { ApiAddress } from '../../core.api'
import { NbResponse } from '../../models'

@Injectable({
    providedIn: 'root'
})
export class LiveTalkService {

    constructor(
        private http: HttpClient
    ) {
    }

    readyToLiveTalk(id, channel) {
        return this.http.post(ApiAddress.TRACKER_LIVE_TALK, {channel},  {params: {id, isLoading: 'false'}}).pipe(
            map((res: NbResponse<any>) => res.data)
        )
    }

    stopLiveTalk(id, channel) {
        return this.http.delete(ApiAddress.TRACKER_LIVE_TALK, {params: {id, isLoading: 'false', channel}})
    }
}
