import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { AvatarModel } from './avatar.model'

@Injectable()
export class AvatarService {

    constructor(
        private http: HttpClient,
    ) {}

    uploadAvatar(body) {
        return this.http.post(ApiAddress.AVATAR, body).pipe(
            map((res: NbResponse<AvatarModel>) => res.data.avatar_path),
        )
    }
}
