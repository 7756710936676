import { Clusterer, Profile } from '../profile/profile.model'
import { Tracker, TrackerLiteral } from '../tracker/tracker.model'
import { Privileges } from '../privileges/privileges.model'
import { POI } from '../poi/poi.model'
import { Country, SimplePoi, SimpleRegion, TimeZone } from './session.model'
import { GeoFence } from '../geo-fence/geo-fence.model'
import { ServiceType } from '../maintenance/maintenance.model'
import { S3Tracker } from '../push/push.model'
import { Fleet } from '../fleet/fleet.model'

export class SetProfile {
    static readonly type = '[Session] set profile'

    constructor(public payload: Profile) {
    }
}

export class ChangeProfile {
    static readonly type = '[Session] change profile'

    constructor(public payload: Profile) {
    }
}

export class SetMapClusterOption {
    static readonly type = '[Session] set map cluster option'

    constructor(public payload: Clusterer) {
    }
}

export class SetTrackers {
    static readonly type = '[Session] set trackers'

    constructor(public payload: Tracker[]) {
    }
}

export class UpdateTracker {
    static readonly type = '[Session] update tracker info'

    constructor(public payload: TrackerLiteral) {
    }
}

export class AddTracker {
    static readonly type = '[Session] add tracker to store'

    constructor(public payload: S3Tracker) {
    }
}

export class RemoveTracker {
    static readonly type = '[Session] remove tracker from store'

    constructor(public payload: S3Tracker) {
    }
}

export class SetGeoFences {
    static readonly type = '[Session] set geofences'

    constructor(public payload: GeoFence[]) {
    }
}

export class EditGeoFence {
    static readonly type = '[Session] edit geofence'

    constructor(public payload: number) {
    }
}

export class SetPOIs {
    static readonly type = '[Session] set pois'

    constructor(public payload: POI[]) {
    }
}

export class UpdatePoi {
    static readonly type = '[Session] update poi'

    constructor(public payload: POI) {
    }
}

export class AddPoi {
    static readonly type = '[Session] add poi'

    constructor(public payload: POI) {
    }
}

export class RemovePoi {
    static readonly type = '[Session] remove poi'

    constructor(public payload: POI) {
    }
}

export class SetPrivileges {
    static readonly type = '[Session] set privileges'

    constructor(public payload: Privileges) {
    }
}

export class SetUnreadNum {
    static readonly type = '[Session] set unread num'

    constructor(public payload: number) {
    }
}

export class AddUnreadNum {
    static readonly type = '[Session] add unread num'

    constructor(public payload: number) {
    }
}

export class ShowMapViewPanel {
    static readonly type = '[Session] toggle map view panel'

    constructor(public payload: boolean) {
    }
}

export class SetCountries {
    static readonly type = '[Session] set countries'

    constructor(public payload: Country[]) {
    }
}

export class SetProvinces {
    static readonly type = '[Session] set provinces'

    constructor(public countryId, public payload) {
    }
}

export class SetTimeZones {
    static readonly type = '[Session] set timezones'

    constructor(public payload: TimeZone[]) {
    }
}

export class SetSelectedTrackerId {
    static readonly type = '[Session] set selected tracker ID'

    constructor(public payload: string) {
    }
}

export class SetSelectedPOIId {
    static readonly type = '[Session] set selected POI ID'

    constructor(public payload: number) {
    }
}

export class SetSelectedGeoFenceId {
    static readonly type = '[Session] set selected geo fence ID'

    constructor(public payload: number) {
    }
}

export class SetServiceType {
    static readonly type = '[Session] set service type'

    constructor(public payload: ServiceType) {
    }
}

export class ResetState {
    static readonly type = '[Session] logout and reset all state'

    constructor() {
    }
}

export class SetStoreStates {
    static readonly type = '[Session] set report state'

    constructor(public payload: string[]) {
    }
}

export class SetFleets {
    static readonly type = '[Session] set fleets'

    constructor(public payload: Fleet[]) {
    }
}

export class UpdateFleets {
    static readonly type = '[Session] update fleets'

    constructor(public payload: TrackerLiteral) {
    }
}

export class UpdateFleet {
    static readonly type = '[Session] update fleet'

    constructor(public payload: Fleet) {
    }
}

export class UpdateTrackerFleet {
    static readonly type = '[Session] update tracker fleet'

    constructor(public payload: Fleet) {
    }
}

export class AddFleet {
    static readonly type = '[Session] add fleet to store'

    constructor(public payload: Fleet) {
    }
}

export class RemoveFleet {
    static readonly type = '[Session] remove fleet from store'

    constructor(public payload: Fleet) {
    }
}

export class SetSelectedFleetIds {
    static readonly type = '[Session] set selected fleets'

    constructor(public payload: number[]) {
    }
}

export class SetSosTracker {
    static readonly type = '[Session] set tracker sos status'
    constructor(public payload: string[]) {}
}

export class ShowMapViewPoi {
    static readonly type = '[Session] set map view poi show or hidden'
    constructor(public payload: boolean) {}
}

export class ShowMapViewFences {
    static readonly type = '[Session] set the main page fence show or hide'
    constructor(public payload: boolean) {}
}

export class ToggleShowMapPoi {
    static readonly type = '[Session] set map poi show or hidden, it\'s inner poi'
    constructor(public payload: boolean) {}
}

export class SetSimpleRegions {
    static readonly type = '[Session] set simple regions. the regions will drawing on map'
    constructor(public payload: SimpleRegion[]) {}
}

export class SetSimplePois {
    static readonly type = '[Session] set simple pois.'
    constructor(public payload: SimplePoi[]) {}
}

export class UpdateSimpleFence {
    static readonly type = '[Session] update simple geo fence'

    constructor(public payload: GeoFence) {
    }
}

export class AddSimpleFence {
    static readonly type = '[Session] add simple geo fence'

    constructor(public payload: GeoFence) {
    }
}

export class RemoveSimpleFence {
    static readonly type = '[Session] remove simple fence'

    constructor(public payload: number) {
    }
}

export class SetIsOpenModifyShape {
    static readonly type = '[Session] set is open modify shape'

    constructor(public payload: boolean) {
    }
}
