import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress, ApiV2Address } from '../../core.api'
import { map, tap } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { PasswordUpdater, Profile } from './profile.model'
import { Store } from '@ngxs/store'
import { SetProfile } from '../session/session.actions'

@Injectable()
export class ProfileService {

    constructor(
        private http: HttpClient,
        private store: Store,
    ) {}

    loadProfile() {
        return this.http.get(ApiAddress.PROFILE).pipe(
            map((res: NbResponse<Profile>) => res.data),
            tap(profile => {
                this.store.dispatch(new SetProfile(profile))
            })
        )
    }

    updateProfileClusterer(clustererOpt) {
        return this.http.put(ApiAddress.PROFILE, clustererOpt).pipe(
          map((res: NbResponse<any>) => res),
        )
    }

    saveProfile(profile) {
        return this.http.put(ApiV2Address.PROFILE, profile).pipe(
            map((res: NbResponse<any>) => res),
        )
    }

    changePassword(updater: PasswordUpdater) {
        return this.http.put(ApiAddress.PASSWORD, updater).pipe(
            map((res: NbResponse<any>) => res.data),
        )
    }

    editEmail(value) {
        return this.http.put(ApiAddress.PROFILEEMAIL, value).pipe(
            map((res: NbResponse<any>) => res),
        )
    }
}
