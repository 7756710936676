import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { catchError, switchAll, tap } from 'rxjs/operators'

// @ts-ignore
@Injectable()

export interface Message {
    source: string
    content: string
    data: any
}

export class WebsocketService {
    messagesSubject$ = new Subject()
    messages$ = this.messagesSubject$.pipe(switchAll(), catchError(e => {
        throw e
    }))
    private socket$: WebSocketSubject<any>

    connect(url: string): void {
        if (!this.socket$ || this.socket$.closed) {
            this.socket$ = this.getNewWebSocket(url)
            this.socket$.subscribe(() => {
                console.log('创建连接')
            })
            const messages = this.socket$.pipe(
                tap({
                    error: error => console.log(error),
                }))
            this.messagesSubject$.next(messages)
        }
    }

    send(msg: any) {
        this.socket$.next(msg)
    }

    close() {
        this.socket$.complete()
    }

    private getNewWebSocket(url) {
        return webSocket(url)
    }

}
