import { State, Action, StateContext } from '@ngxs/store'
import { ImmediatePhoto } from '../fleet/fleet.model'
import { CacheImmediatePhoto, ResetLiveVideoState, SetPlayVideoTrackerId, ShowLiveVideo } from './live-video.actions'
import { Injectable } from '@angular/core'

export interface LiveVideo {
    playVideoTrackerId: string
    showLiveVideo: boolean
    immediatePhoto: ImmediatePhoto
}

const initLiveVideoState: LiveVideo = {
    playVideoTrackerId: '',
    showLiveVideo: false,
    immediatePhoto: null,
}

@State<LiveVideo>({
    name: 'liveVideo',
    defaults: initLiveVideoState
})
@Injectable()
export class LiveVideoState {
    constructor() {}

    @Action(SetPlayVideoTrackerId)
    setPlayVideoTrackerId(ctx: StateContext<LiveVideo>, action: SetPlayVideoTrackerId) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            playVideoTrackerId: action.payload
        })
    }

    @Action(ShowLiveVideo)
    showLiveVideo(ctx: StateContext<LiveVideo>, action: ShowLiveVideo) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            showLiveVideo: action.payload
        })
    }

    @Action(CacheImmediatePhoto)
    cacheNewPhoto(ctx: StateContext<LiveVideo>, action: CacheImmediatePhoto) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            immediatePhoto: action.payload
        })
    }

    @Action(ResetLiveVideoState)
    resetLiveVideoState(ctx: StateContext<LiveVideo>) {
        ctx.setState({
            ...initLiveVideoState
        })
    }
}

