import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress, ApiV2Address } from '../../core.api'
import { map, tap } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { Store } from '@ngxs/store'
import { Country, Province, SimplePoi, SimpleRegion, TimeZone } from './session.model'
import { SetCountries, SetProvinces, SetSimplePois, SetSimpleRegions, SetTimeZones } from './session.actions'
import { Observable } from 'rxjs'
import { isArray } from 'lodash'
import { decodeLatLng, preProcessFence } from '../../util'

@Injectable()
export class SessionService {

    constructor(
        private http: HttpClient,
        private store: Store,
    ) {}

    hasPermission(needs: string[]): boolean {
        const permissions = this.store.selectSnapshot(state => state.session.permissions)
        let isAuth = true
        if (isArray(needs)) {
            const tempArr = []
            needs.forEach((item) => {
                const target = permissions.find((pItem) => pItem === item)
                if (target) { tempArr.push(target) }
            })
            isAuth = tempArr.length === needs.length

            return isAuth

        } else {
            throw Error('Input data should be array')
        }
    }

    getTrackers() {
        return this.store.selectSnapshot(state => state.session.trackers)
    }

    getTrackerById(id) {
        const trackers = this.store.selectSnapshot(state => state.session.trackers)

        return trackers.find(tracker => tracker.id === id)
    }

    getFleets() {
        return this.store.selectSnapshot(state => state.session.fleets)
    }

    getFleetById(id) {
        const fleets = this.store.selectSnapshot(state => state.session.fleets)

        return fleets.find(fleet => fleet.id === id)
    }

    getProfile(key?) {
        const profile = this.store.selectSnapshot(state => state.session.profile)

        return key ? profile[key] : profile
    }

    getCountries() {
        let countries$
        const countries = this.store.selectSnapshot(state => state.session.countries)
        if (countries) {
            countries$ = new Observable(observer => {
                observer.next(countries)
                observer.complete()
            })
        } else {
            countries$ = this.loadCountries()
        }

        return countries$
    }

    getProvinces(countryId) {
        let provinces$
        const provinces = this.store.selectSnapshot(state => state.session.provincesObj[countryId])
        if (provinces) {
            provinces$ = new Observable(observer => {
                observer.next(provinces)
                observer.complete()
            })
        } else {
            provinces$ = this.loadProvinces(countryId)
        }

        return provinces$
    }

    getTimeZones() {
        let timezones$
        const timezones = this.store.selectSnapshot(state => state.session.timezones)
        if (timezones) {
            timezones$ = new Observable(observer => {
                observer.next(timezones)
                observer.complete()
            })
        } else {
            timezones$ = this.loadTimeZones()
        }

        return timezones$
    }

    loadTimeZones() {
        return this.http.get(ApiV2Address.TIMEZONES).pipe(
            map((res: NbResponse<TimeZone[]>) => res.data),
            tap(timezones => {
                this.store.dispatch(new SetTimeZones(timezones))
            })
        )
    }

    loadCountries() {
        return this.http.get(ApiAddress.COUNTRIES).pipe(
            map((res: NbResponse<Country[]>) => res.data),
            tap(countries => {
                this.store.dispatch(new SetCountries(countries))
            })
        )
    }

    loadProvinces(countryId) {
        // const provinceName = `provincesOfCountry${countryId}`
        return this.http.get(ApiAddress.PROVINCES, {params: { id: countryId }}).pipe(
            map((res: NbResponse<Province[]>) => res.data),
            tap(provinces => {
                this.store.dispatch(new SetProvinces(countryId, provinces))
            })
        )
    }

    loadSimpleRegions() {
        return this.http.get(ApiAddress.SIMPLE_REGIONS).pipe(
            map((res: NbResponse<SimpleRegion[]>) => res.data),
            map((shapes) => {
                return shapes.map(preProcessFence)
            }),
            tap((shapes) => {
                this.store.dispatch(new SetSimpleRegions(shapes))
            })
        )
    }

    loadSimplePois() {
        return this.http.get(ApiAddress.SIMPLE_POIS).pipe(
            map((res: NbResponse<SimplePoi[]>) => res.data),
            map(pois => {
                return pois.map(poi => {
                    poi.latitude = decodeLatLng(poi.latitude)
                    poi.longitude = decodeLatLng(poi.longitude)

                    return poi
                })
            }),
            tap((pois) => {
                this.store.dispatch(new SetSimplePois(pois))
            })
        )
    }
}
