import { State, Action, StateContext } from '@ngxs/store'
import { AddStep, EndGuide, FulfillStep, NextGuide } from './guide.actions'
import { Injectable } from '@angular/core'

export interface Guide {
    steps: GuideStep[]
    stepIndex: number
    ended: boolean
}

const initGuideState: Guide = {
    steps: [],
    stepIndex: 0,
    ended: false
}


export interface GuideStep {
    router: string
    played: boolean
}

@State<Guide>({
    name: 'guide',
    defaults: initGuideState
})
@Injectable()
export class GuideState {
    constructor(
    ) {
    }

    @Action(AddStep)
    addStep(ctx: StateContext<Guide>, action: AddStep) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            steps: [...state.steps, action.payload]
        })
    }

    @Action(FulfillStep)
    fulfillStep(ctx: StateContext<Guide>) {
        const state = ctx.getState()
        const newSteps = [...state.steps]
        const currentStep = newSteps[state.stepIndex]
        newSteps[state.stepIndex] = { ...currentStep, played: true }
        ctx.setState({
            ...state ,
            steps: newSteps
        })
    }

    @Action(NextGuide)
    nextGuide(ctx: StateContext<Guide>) {
        const state = ctx.getState()
        const newStepIndex = state.stepIndex + 1
        ctx.setState({
            ...state,
            stepIndex: newStepIndex
        })
    }

    @Action(EndGuide)
    endGuide(ctx: StateContext<Guide>) {
        ctx.setState({
            ...initGuideState
        })
    }
}

