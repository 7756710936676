import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { interval, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as io from 'socket.io-client'
import { ApiAddress } from '../../core.api'
import { NbResponse } from '../../models'
import { decodeLatLng } from '../../util'
import { UpdateBusPosition } from './bus-position.actions'
import { GeoFence, POI, PushConnectParams, Tracker } from './bus-position.model'

@Injectable()
export class BusPositionService {
    socket
    reConnectSub
    token
    pushParams

    constructor(
      private http: HttpClient,
      private store: Store,
    ) {
    }

    getGeoFenceByToken(token) {
        return this.http.get(ApiAddress.BUS_POSITION_GEOFENCE, { params: { token } }).pipe(
          map((res: NbResponse<GeoFence>) => res.data),
          map(geoFence => {
              if (geoFence.circle) {
                  geoFence.circle.latitude = decodeLatLng(geoFence.circle.latitude)
                  geoFence.circle.longitude = decodeLatLng(geoFence.circle.longitude)
              }
              if (geoFence.polygon) {
                  geoFence.polygon.map(polygon => {
                      polygon.latitude = decodeLatLng(polygon.latitude)
                      polygon.longitude = decodeLatLng(polygon.longitude)
                  })
              }

              return geoFence
          })
        )
    }

    getTrackerInfoByToken(token) {
        return this.http.get(ApiAddress.BUS_POSITION_TRACKERS, { params: { token } }).pipe(
          map((res: NbResponse<Tracker[]>) => res.data),
          map(trackers => {
              trackers.map(tracker => {
                  tracker.latitude = decodeLatLng(tracker.latitude)
                  tracker.longitude = decodeLatLng(tracker.longitude)
                  tracker.isInArea = false
              })

              return trackers
          })
        )
    }

    getPoiByToken(token) {
        return this.http.get(ApiAddress.BUS_POSITION_POIS, { params: { token } }).pipe(
          map((res: NbResponse<POI[]>) => res.data),
          map(pois => {
              return pois.map(poi => {
                  poi.latitude = decodeLatLng(poi.latitude)
                  poi.longitude = decodeLatLng(poi.longitude)

                  return poi
              })
          })
        )
    }

    registerPush(token): Observable<PushConnectParams> {
        this.token = token

        return this.http.get(ApiAddress.BUS_POSITION_PUSH, { params: { token } })
        .pipe(map((res: NbResponse<PushConnectParams>) => {
            this.pushParams = res.data

            return res.data
        }))
    }

    connectPush(pushParams) {
        this.socket = io.connect('', {
            path: '/push/2.0/socket.io',
            query: {
                packet_type: 'C1',
                push_id: pushParams.wspush_id,
                psd: pushParams.wspush_key,
                devid: pushParams.devid,
                from: 0
            },
            multiplex: false,
            forceNew: true,
            reconnection: false,
            timeout: 30000,
            transports: [ 'websocket' ]
        })

        this.socket.on('connect', () => {
            console.log('connected')
            this.removeReconnectSub()
        })

        this.socket.on('connect_failed', (e) => {
            console.log('connect failed', e)
            this.reConnection(e)
        })
        this.socket.on('error', (e) => {
            console.log('error', e)
            this.reConnection(e)
        })
        this.socket.on('connect_error', (e) => {
            console.log('connect error', e)
            this.reConnection(e)
        })
        this.socket.on('disconnect', (e) => {
            console.log('disconnect', e)
            this.reConnection(e)
        })
        this.socket.on('connect_timeout', (e) => {
            console.log('connect_timeout', e)
            this.reConnection(e)
        })
        this.socket.on('api/resp', (pkg) => {
            console.log(pkg)
            this.resolvePackage(pkg)
        })
    }

    destroy() {
        if (this.socket) {
            this.socket.close()
            this.socket = null
        }
        this.removeReconnectSub()
    }

    private removeReconnectSub() {
        if (this.reConnectSub) {
            this.reConnectSub.unsubscribe()
            this.reConnectSub = null
        }
    }

    private reConnection(message: string) {
        if (/401/.test(message)) {
            this.registerPush(this.token).subscribe((pushParams) => {
                this.connectPush(pushParams)
            }, () => {
                this.intervalReconnection()
            })
        } else {
            this.intervalReconnection()
        }
    }

    private intervalReconnection() {
        if (this.reConnectSub) {
            return
        }

        this.reConnectSub = interval(15000).subscribe(() => {
            this.connectPush(this.pushParams)
        })
    }

    private resolvePackage(pkg) {
        if (pkg.packet_type === 'S5') {
            // tracker location change
            const s5Trackers = this.pkgToModelData<Tracker[]>(pkg)
            s5Trackers.forEach(tracker => {
                if (tracker.longitude) {
                    tracker.longitude = decodeLatLng(tracker.longitude)
                }

                if (tracker.latitude) {
                    tracker.latitude = decodeLatLng(tracker.latitude)
                }
                this.store.dispatch(new UpdateBusPosition(tracker))
            })

            return ''
        }
    }

    private pkgToModelData<T>(pkg) {
        return <T>pkg.res
    }
}
