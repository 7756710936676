import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { interval } from 'rxjs'
import * as moment from 'moment-timezone'

export interface TimestampPair {
    start_time: UnixTimestamp;
    end_time: UnixTimestamp;
}

@Injectable()
export class TimerService {
    profile
    private minuteTimer$ = interval(60 * 1000)

    constructor(
        private store: Store
    ) {
        this.store.select(state => state.session.profile)
            .subscribe(profile => {
                this.profile = profile
            })
    }

    getMinuteTimer$() {
        return this.minuteTimer$
    }

    getDate(date?): any {
        return moment.tz(date, this.profile.timezone_city)
    }

    turnToAsiaDate(date?): any {
        return moment.tz(date, 'Asia/Shanghai')
    }

    addTime(timestamp, num, dataType = 'hour') {
        return moment(timestamp * 1000).add(dataType, num).unix()
    }

    subtractTime(timestamp, num, dataType = 'hour') {
        return moment(timestamp * 1000).subtract(dataType, num).unix()
    }

    timeCondition(val, timestamp?) {
        return val.includes('previous')
            ? this.previousCondition(val, timestamp)
            : this.condition(val)
    }

    tableTitleByDate(timestamp, minUnits) {
        const date = this.getDate(timestamp * 1000)
        let format
        switch (minUnits) {
            case 1:
                format = 'YYYY'
                break
            case 2:
                format = 'MMM YYYY'
                break
            case 3:
                format = this.profile.date_format === 0 ? 'DD MMM YYYY' : 'MMM DD YYYY'
                break
            default:
                break
        }

        return date.format(format)
    }

    countHours (timestamp): number {
        const time = this.getDate(timestamp * 1000)
        const hours = time.hour()
        const minutes = time.minute()
        const second = time.second()

        return hours + ( minutes / 60 ) + ( second / 60 / 60 )
    }

    private condition(val): TimestampPair {
        let dataType = ''
        let dataSubtract = 0
        let endOfType = 'day'

        switch (val) {
            case 'all':
                dataType = 'day'
                dataSubtract = 0
                break
            case 'lastHour':
                dataType = 'hour'
                endOfType = 'hour'
                dataSubtract = 1
                break
            case 'today':
                dataType = 'day'
                dataSubtract = 0
                break
            case 'yesterday':
                dataType = 'day'
                dataSubtract = 1
                break
            case 'thisWeek':
                dataType = 'week'
                dataSubtract = 0
                break
            case 'lastWeek':
                dataType = 'week'
                dataSubtract = 1
                break
            case 'thisMonth':
                dataType = 'month'
                dataSubtract = 0
                break
            case 'lastMonth':
                dataType = 'month'
                dataSubtract = 1
                break
            case 'thisYear':
                dataType = 'year'
                dataSubtract = 0
                break
            case 'lastYear':
                dataType = 'year'
                dataSubtract = 1
                break
            default:
                break
        }

        return {
            start_time: val === 'all'
                ? 1
                : this.getDate()
                    .subtract(dataSubtract, dataType)
                    .startOf(dataType)
                    .unix(),
            end_time: moment.min(
                this.getDate()
                    .subtract(dataSubtract, dataType)
                    .endOf(dataType),
                this.getDate())
                .endOf(endOfType as moment.unitOfTime.StartOf)
                .unix()
        }
    }

    private previousCondition(val, timestamp): TimestampPair {
        let dataType
        switch (val) {
            case 'previousDays':
                dataType = 'day'
                break
            case 'previousMonths':
                dataType = 'month'
                break
            default:
                dataType = 'year'
                break
        }

        return {
            start_time: this.getDate(timestamp * 1000)
                .startOf(dataType)
                .unix(),
            end_time: moment.min(this.getDate(), this.getDate(timestamp * 1000).endOf(dataType))
                .endOf('day')
                .unix()
        }
    }
}
