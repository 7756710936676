import { Injectable } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Store } from '@ngxs/store'
import { EMPTY, Observable } from 'rxjs'
import { AddRequest } from '../states/loading/loading.store'
import { NzMessageService } from 'ng-zorro-antd/message'

const REST_PARAMS = /(:[^/]+)/g
export const NB_LOADING_KEY = 'nb-loading'

@Injectable()
export class RequestRestInterceptor implements HttpInterceptor {
    constructor(
        private store: Store,
        private messageService: NzMessageService,
    ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let realReq = request
        const matched = request.url.match(REST_PARAMS)
        if (request.params.get('start_time') > request.params.get('end_time')) {
            this.messageService.error('Start time can not over End time')

            return EMPTY
        }

        if (matched && matched.length > 0) {
            matched.forEach(function (param) {
                const paramName = param.substring(1)
                const pVal = request.params.get(paramName)
                if (!pVal) {
                    throw new URIError(`[REST] No target value for replacing resource ${paramName} in url.
                         Params in url must be placed in params within RequestOptions`)
                }

                realReq = realReq.clone({
                    url: realReq.url.replace(param, pVal),
                    params: realReq.params.delete(paramName),
                })
            })
        }

        if (realReq.params.get('isLoading')) {
            realReq = realReq.clone({
                params: realReq.params.delete('isLoading'),
                headers: realReq.headers.append(NB_LOADING_KEY, 'false')
            })
        }

        if (request.params.get('isLoading') !== 'false') {
            this.store.dispatch(new AddRequest())
        }

        return next.handle(realReq)
    }
}

export const requestInterceptor = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestRestInterceptor,
        multi: true
    }
]
