import { Action, State, StateContext } from '@ngxs/store'
import { Injectable } from '@angular/core'

export interface ConnectParams {
    devid: string
    wspush_id: string
    wspush_key: string
}

export class PushRegisterSuccess {
    static readonly type = '[PUSH] register the push success'
    constructor(public payload: ConnectParams) {}
}

export class Connected {
    static readonly type = '[PUSH] push connected'
    constructor() {}
}

export class ConnectedError {
    static readonly type = '[PUSH] push connect error'
    constructor() {}
}

export class ConnectedFailed {
    static readonly type = '[PUSH] push connect failed'
    constructor() {}
}

export class Disconnected {
    static readonly type = '[PUSH] disconnected'
    constructor() {}
}

export interface PushStore {
    connected: boolean
    wspush: ConnectParams
}

@State<PushStore>({
    name: 'push',
    defaults: {
        connected: false,
        wspush: null,
    }
})
@Injectable()
export class PushState {
    constructor(
    ) {}

    @Action(PushRegisterSuccess)
    register(ctx: StateContext<PushStore>, action: PushRegisterSuccess) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            wspush: action.payload
        })
    }

    @Action(Connected)
    connected(ctx: StateContext<PushStore>) {
        const state = ctx.getState()

        ctx.setState({
            ...state,
            connected: true
        })
    }

    @Action(ConnectedError)
    connectError(ctx: StateContext<PushStore>) {
        const state = ctx.getState()

        ctx.setState({
            ...state,
            connected: false
        })
    }

    @Action(Disconnected)
    disconnected(ctx: StateContext<PushStore>) {
        const state = ctx.getState()

        ctx.setState({
            ...state,
            connected: false
        })
    }

    @Action(ConnectedFailed)
    connectFailed(ctx: StateContext<PushStore>) {
        const state = ctx.getState()

        ctx.setState({
            ...state,
            connected: false
        })
    }
}
