import { BusPositionState } from './bus-position/bus-position.store'
import { LiveVideoState } from './live-video/live-video.store'
import { SessionState } from './session/session.store'
import { MapState } from './map/map.store'
import { PushState } from './push/push.store'
import { GuideState } from './guide/guide.store'
import { LoadingState } from './loading/loading.store'
import { AuthenticationState } from './authentication/authentication.store'
import { ShareAccountState } from './share-account/share-account.store'
import { SharePositionState } from './share-position/share-position.store'

export const AllStates = [
    SessionState,
    MapState,
    PushState,
    GuideState,
    LoadingState,
    AuthenticationState,
    ShareAccountState,
    SharePositionState,
    BusPositionState,
    LiveVideoState,
]
