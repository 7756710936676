import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ApiAddress } from '../../core.api'
import { map, tap } from 'rxjs/operators'
import { NbResponse } from '../../models'
import { PageInfo, POI } from './poi.model'
import { Store } from '@ngxs/store'
import { SetPOIs } from '../session/session.actions'
import { decodeLatLng } from '../../util'

@Injectable()
export class PoiService {

    constructor(
        private http: HttpClient,
        private store: Store,
    ) {}

    loadPOIs() {
        return this.http.get(ApiAddress.POIS).pipe(
            map((res: NbResponse<POI[]>) => res.data),
            map(pois => {
                return pois.map(poi => {
                    poi.latitude = decodeLatLng(poi.latitude)
                    poi.longitude = decodeLatLng(poi.longitude)

                    return poi
                })
            }),
            tap(pois => {
                this.store.dispatch(new SetPOIs(pois))
            })
        )
    }

    getPoiById(id) {
        return this.http.get(ApiAddress.POI, {params: {id}}).pipe(
            map((res: NbResponse<POI>) => res.data),
            map(poi => {
                poi.latitude = decodeLatLng(poi.latitude)
                poi.longitude = decodeLatLng(poi.longitude)

                return poi
            })
        )
    }

    // setting poi 页面
    loadPOIsSetting(page) {
        return this.http.get(ApiAddress.POIS, {params: {page}}).pipe(
            map((res: NbResponse<POI[], PageInfo>) => (<[POI[], PageInfo]>[res.data, res.meta])),
            tap(([data, meta]) => {
                return data.map(poi => {
                    poi.latitude = decodeLatLng(poi.latitude)
                    poi.longitude = decodeLatLng(poi.longitude)

                    return (<[POI[], PageInfo]>[data, meta])
                })
            })
        )
    }

    loadPOIById(id) {
        return this.http.get(ApiAddress.POI, {params: {id}}).pipe(
            map((res: NbResponse<POI>) => res.data),
            map(poi => {
                poi.latitude = decodeLatLng(poi.latitude)
                poi.longitude = decodeLatLng(poi.longitude)

                return poi
            })
        )
    }

    addPOI(body) {
        return this.http.post(ApiAddress.POIS, body)
    }

    simplePoi(id, body) {
        return this.http.put(ApiAddress.SIMPLE_POI, body, {params: {id}})
    }

    updatePOIById(id, body) {
        return this.http.put(ApiAddress.POI, body, {params: {id}})
    }

    editPOI(id, body) {
        return this.http.put(ApiAddress.POI, body, {params: {id}})
    }

    deletePOIById(id) {
        return this.http.delete(ApiAddress.POI, {params: {id}}).pipe(
            map((res: NbResponse<any>) => res)
        )
    }
}
