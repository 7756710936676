export class ShowTracker {
    static readonly type = '[Share Position] show tracker'
    constructor(public payload: string) {}
}

export class HideTracker {
    static readonly type = '[Share Position] hide tracker'
    constructor() {}
}

export class SetGoogleKey {
    static readonly type = '[Share Position] set google key'
    constructor(public payload: string) {}
}
