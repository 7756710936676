import { State, Action, StateContext } from '@ngxs/store'
import {
    HidePoisFromMap, HideTrackersFromMap, RemoveCreateGeoFenceData,
    RemoveCreatePoiData, SaveCreateGeoFenceData, SaveCreatePoiData,
    ShowPoiInMap, ShowTrackerInMap, CenterMap, MapZoomChanged, MapLegendType,
} from './map.actions'
import { CreatePoiData } from '../poi/poi.model'
import { CreateGeoFenceData } from '../geo-fence/geo-fence.model'
import { Injectable } from '@angular/core'

export interface MapStore {
    showTrackers: boolean
    showPois: boolean
    createPoiData: CreatePoiData
    createGeoFenceData: CreateGeoFenceData
    zoom: number
    mapCenter: google.maps.LatLngLiteral
    mapLegendType: string
}

const initMapState: MapStore = {
    showTrackers: true,
    showPois: true,
    createPoiData: null,
    createGeoFenceData: null,
    zoom: 4,
    mapCenter: null,
    mapLegendType: 'tracker'
}

@State<MapStore>({
    name: 'map',
    defaults: initMapState
})
@Injectable()
export class MapState {
    constructor(
    ) {}

    @Action(ShowTrackerInMap)
    showTrackerInMap(ctx: StateContext<MapStore>) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            showTrackers: true
        })
    }

    @Action(ShowPoiInMap)
    showPoiInMap(ctx: StateContext<MapStore>) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            showPois: true
        })
    }

    @Action(HideTrackersFromMap)
    hideTrackerFromMap(ctx: StateContext<MapStore>) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            showTrackers: false
        })
    }

    @Action(HidePoisFromMap)
    hidePoiFromMap(ctx: StateContext<MapStore>) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            showPois: false
        })
    }

    @Action(SaveCreatePoiData)
    saveCreatePoiData(ctx: StateContext<MapStore>, action: SaveCreatePoiData) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            createPoiData: action.payload
        })
    }

    @Action(RemoveCreatePoiData)
    RemoveCreatePoiData(ctx: StateContext<MapStore>) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            createPoiData: null
        })
    }

    @Action(SaveCreateGeoFenceData)
    saveCreateGeoFenceData(ctx: StateContext<MapStore>, action: SaveCreateGeoFenceData) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            createGeoFenceData: action.payload
        })
    }

    @Action(RemoveCreateGeoFenceData)
    RemoveCreatePoiGeoFenceData(ctx: StateContext<MapStore>) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            createGeoFenceData: null
        })
    }

    @Action(MapZoomChanged)
    mapZoomChanged(ctx: StateContext<MapStore>, action: MapZoomChanged) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            zoom: action.payload
        })
    }

    @Action(CenterMap)
    centerMap(ctx: StateContext<MapStore>, action: CenterMap) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            mapCenter: action.payload
        })
    }

    @Action(MapLegendType)
    mapLegendType(ctx: StateContext<MapStore>, action: MapLegendType) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            mapLegendType: action.payload
        })
    }
}
