import { NgModule, Optional, SkipSelf } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { throwIfAlreadyLoaded } from './module-import-guard'
import { NgxsModule } from '@ngxs/store'
import { AllStates } from './states'
import { CoreServices } from './services'
import { environment } from '../environments/environment'
import { requestInterceptor } from './http/request.interceptor'
import { responseInterceptor } from './http/response.interceptor'
import { TimerService } from './service/timer'

const isDev = !environment.production
const modules = [
    HttpClientModule,
    NgxsModule.forRoot(AllStates, { developmentMode: isDev }),
]

// if (isDev) {
//     modules.push(NgxsLoggerPluginModule.forRoot())
// }

@NgModule({
    imports: [
        ...modules
    ],
    providers: [
        ...requestInterceptor,
        ...responseInterceptor,
        ...CoreServices,
        TimerService,
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule')
    }
}

