import { State, Action, StateContext, Store } from '@ngxs/store'
import {
    ResetState,
    SetCountries,
    SetProvinces,
    SetPrivileges,
    SetProfile,
    SetSelectedTrackerId,
    SetTimeZones,
    SetTrackers,
    SetUnreadNum,
    SetGeoFences,
    EditGeoFence,
    SetServiceType,
    SetStoreStates,
    UpdateTracker,
    AddTracker,
    RemoveTracker,
    UpdatePoi,
    AddPoi,
    RemovePoi,
    ChangeProfile,
    AddUnreadNum,
    SetSelectedPOIId,
    SetSelectedGeoFenceId,
    SetFleets,
    UpdateFleet,
    AddFleet,
    RemoveFleet,
    UpdateTrackerFleet,
    UpdateFleets,
    SetSelectedFleetIds,
    SetSosTracker,
    ShowMapViewPoi,
    ShowMapViewPanel,
    ToggleShowMapPoi,
    SetSimpleRegions,
    SetSimplePois,
    ShowMapViewFences, SetMapClusterOption, UpdateSimpleFence, AddSimpleFence, RemoveSimpleFence, SetIsOpenModifyShape,
} from './session.actions'
import { Profile } from '../profile/profile.model'
import { Tracker } from '../tracker/tracker.model'
import { Privileges } from '../privileges/privileges.model'
import { POI } from '../poi/poi.model'
import { Country, ProvincesObj, SimplePoi, SimpleRegion, TimeZone } from './session.model'
import { GeoFence } from '../geo-fence/geo-fence.model'
import { ServiceType } from '../maintenance/maintenance.model'
import { permissionsDict, rolePermissions, sortTrackers } from '../../util'
import { TrackerService } from '../tracker/tracker.service'
import { PoiService } from '../poi/poi.service'
import { cloneDeep } from 'lodash'
import { StorageKeys } from '../../core.constants'
import { Fleet } from '../fleet/fleet.model'
import { Injectable } from '@angular/core'

export interface Session {
    profile: Profile
    trackers: Tracker[]
    fleets: Fleet[]
    geoFences: GeoFence[]
    editGeoFence: number
    simplePois: SimplePoi[]
    simpleRegions: SimpleRegion[]
    privileges: Privileges
    unreadNum: number
    showMapViewPanel: boolean
    countries: Country[]
    timezones: TimeZone[]
    provincesObj: ProvincesObj
    selectedTrackerId: string
    selectedPOIId: number
    selectedGeoFenceId: number
    selectedFleetIds: number[]
    serviceType: ServiceType
    reportStates: string[]
    permissions: string[]
    editPOI: POI
    showMapViewPoi: boolean
    showFences: boolean
    showMapPoi: boolean // the map inner poi
    showModifyShape: boolean
}

const initSessionState: Session = {
    profile: null,
    trackers: [],
    fleets: [],
    geoFences: [],
    editGeoFence: null,
    simplePois: [],
    simpleRegions: [],
    privileges: null,
    unreadNum: 0,
    showMapViewPanel: true,
    countries: null,
    timezones: null,
    provincesObj: {},
    selectedTrackerId: null,
    selectedPOIId: null,
    selectedGeoFenceId: null,
    selectedFleetIds: [],
    serviceType: null,
    reportStates: [],
    permissions: [],
    editPOI: null,
    showMapViewPoi: true,
    showFences: true,
    showMapPoi: true,
    showModifyShape: false
}

@State<Session>({
    name: 'session',
    defaults: initSessionState
})
@Injectable()
export class SessionState {
    constructor(
      private store: Store,
      private trackerService: TrackerService,
      private poiService: PoiService,
    ) {
    }

    @Action(SetProfile)
    setProfile(ctx: StateContext<Session>, action: SetProfile) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            profile: action.payload
        })
    }

    @Action(ChangeProfile)
    changeProfile(ctx: StateContext<Session>, action: ChangeProfile) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            profile: { ...state.profile, ...action.payload }
        })
    }

    @Action(SetMapClusterOption)
    showMapPoiClusterer(ctx: StateContext<Session>, action: SetMapClusterOption) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            profile: {
                ...state.profile,
                cluster: {
                    ...state.profile.cluster,
                    ...action.payload
                }
            }
        })
    }

    @Action(SetTrackers)
    setTrackers(ctx: StateContext<Session>, action: SetTrackers) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            trackers: sortTrackers(action.payload)
        })
    }

    @Action(UpdateTracker)
    updateTracker(ctx: StateContext<Session>, action: UpdateTracker) {
        const state = ctx.getState()
        const trackers = state.trackers
        const tracker = action.payload
        const index = trackers.findIndex(item => item.id === tracker.id)
        if (index < 0) {
            return new Error('[Update Tracker] no target tracker found!')
        }
        const newTracker = { ...trackers[ index ], ...tracker }
        const newTrackers = [...trackers]
        newTrackers[ index ] = newTracker

        ctx.setState({
            ...state,
            trackers: sortTrackers(newTrackers)
        })
        localStorage.setItem(state.profile.id, JSON.stringify(state.trackers))
    }

    @Action(AddTracker)
    addTracker(ctx: StateContext<Session>, action: AddTracker) {
        const state = ctx.getState()
        const isExist = !!state.trackers.find(item => item.id === action.payload.id)
        if (isExist) {
            console.error('tracker is exist')

            return
        }
        this.trackerService.getTrackerById(action.payload.id).subscribe(tracker => {
            const newTrackers = [...state.trackers, tracker]
            ctx.setState({
                ...state,
                trackers: sortTrackers(newTrackers)
            })
        })
    }

    @Action(RemoveTracker)
    removeTracker(ctx: StateContext<Session>, action: RemoveTracker) {
        const state = ctx.getState()
        const newTrackers = state.trackers.filter(item => item.id !== action.payload.id)
        ctx.setState({
            ...state,
            trackers: newTrackers
        })
    }

    @Action(SetFleets)
    setFleets(ctx: StateContext<Session>, action: SetFleets) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            fleets: action.payload
        })
    }

    @Action(UpdateFleets)
    updateFleets(ctx: StateContext<Session>, action: UpdateFleets) {
        const state = ctx.getState()
        const tracker = action.payload
        const newFleets = cloneDeep(state.fleets)
        newFleets.forEach(fleet => {
            const index = fleet.tids.findIndex(tid => tid === tracker.id)
            const findFleet = tracker.fleets.find(trackerFleet => trackerFleet.id === fleet.id)
            if (!findFleet && index > -1) {// 当前fleet存在tracker id并且tracker fleets中不存在当前fleet  即: tracker取消关联当前fleet
                fleet.tids.splice(index, 1)
            }
            if (findFleet && index < 0) {// 当前fleet不存在tracker id并且tracker fleets中存在当前fleet  即: tracker关联当前fleet
                fleet.tids.push(tracker.id)
            }
        })

        ctx.setState({
            ...state,
            fleets: newFleets
        })
    }

    @Action(UpdateFleet)
    updateFleet(ctx: StateContext<Session>, action: UpdateFleet) {
        const state = ctx.getState()
        const fleets = state.fleets
        const fleet = action.payload
        const index = fleets.findIndex(item => item.id === fleet.id)
        if (index < 0) {
            return new Error('[Update Fleet] no target fleet found!')
        }
        const newFleet = { ...fleets[ index ], ...fleet }
        const newFleets = [...fleets]
        newFleets[ index ] = newFleet

        if (fleet.tids) {
            this.store.dispatch(new UpdateTrackerFleet(newFleet))
        }

        ctx.setState({
            ...state,
            fleets: newFleets
        })
    }

    @Action(UpdateTrackerFleet)
    updateTrackerFleet(ctx: StateContext<Session>, action: UpdateTrackerFleet) {
        const state = ctx.getState()
        const newTrackers = cloneDeep(state.trackers)
        const newFleet = action.payload
        newTrackers.forEach(tracker => {
            const index = tracker.fleets.findIndex(fleet => fleet.id === newFleet.id)
            const tidIndex = newFleet.tids.findIndex(tid => tid === tracker.id)
            if (index > -1) {// 此fleet存在于原来的tracker里
                if (tidIndex > -1) { // 此tracker还在新fleet中
                    Object.assign(tracker.fleets[ index ], newFleet)
                } else { // 此tracker不在新fleet中
                    tracker.fleets.splice(index, 1)
                }
            } else {// 此fleet不存在于原来的tracker里
                if (tidIndex > -1) { // 此tracker还在新label中
                    const { id, name } = newFleet
                    tracker.fleets.push({ id, name })
                }
            }
        })

        ctx.setState({
            ...state,
            trackers: newTrackers
        })
    }

    @Action(AddFleet)
    addFleet(ctx: StateContext<Session>, action: AddFleet) {
        const state = ctx.getState()
        const fleets = state.fleets
        const newFleet = action.payload
        const findFleet = fleets.find(fleet => fleet.id === newFleet.id)
        if (!findFleet) {
            const newFleets = [...state.fleets, newFleet]
            const newTrackers = cloneDeep(state.trackers)
            newFleet.tids.forEach(tid => {
                const tracker = newTrackers.find(tracker => tracker.id === tid)
                if (tracker && !tracker.fleets.find(fleet => fleet.id === newFleet.id)) {
                    const { id, name } = newFleet
                    tracker.fleets.push({ id, name })
                }
            })
            ctx.setState({
                ...state,
                fleets: newFleets,
                trackers: newTrackers
            })
        }
    }

    @Action(RemoveFleet)
    removeFleet(ctx: StateContext<Session>, action: RemoveFleet) {
        const state = ctx.getState()
        const newFleet = action.payload
        const newFleets = state.fleets.filter(item => item.id !== newFleet.id)
        const newTrackers = cloneDeep(state.trackers)
        newTrackers.forEach(tracker => {
            const fleets = tracker.fleets
            const index = fleets.findIndex(fleet => fleet.id === newFleet.id)
            if (index > -1) {
                fleets.splice(index, 1)
            }
        })
        ctx.setState({
            ...state,
            fleets: newFleets,
            trackers: newTrackers
        })
    }

    @Action(SetSelectedFleetIds)
    setSelectedFleetIds(ctx: StateContext<Session>, action: SetSelectedFleetIds) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            selectedFleetIds: action.payload
        })
    }

    @Action(SetGeoFences)
    setGeoFences(ctx: StateContext<Session>, action: SetGeoFences) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            geoFences: action.payload
        })
    }

    @Action(EditGeoFence)
    editGeoFence(ctx: StateContext<Session>, action: EditGeoFence) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            editGeoFence: action.payload
        })
    }

    @Action(UpdatePoi)
    updatePoi(ctx: StateContext<Session>, action: UpdatePoi) {
        const state = ctx.getState()
        const poi = this.poiToSimplePoi(action.payload)
        const pois = state.simplePois
        const index = pois.findIndex(item => item.id === poi.id)
        if (index < 0) {
            return
        }
        const distPoi = pois[ index ]
        const newPoi = { ...distPoi, ...poi }
        const newPois = [...pois]
        newPois[ index ] = newPoi
        ctx.setState({
            ...state,
            simplePois: newPois
        })
    }

    @Action(AddPoi)
    addPoi(ctx: StateContext<Session>, action: AddPoi) {
        this.poiService.getPoiById(action.payload.id).subscribe(poi => {
            const state = ctx.getState()
            ctx.setState({
                ...state,
                simplePois: [...state.simplePois, this.poiToSimplePoi(poi)]
            })
        })
    }

    @Action(RemovePoi)
    removePoi(ctx: StateContext<Session>, action: RemovePoi) {
        const state = ctx.getState()
        const newPois = state.simplePois.filter(item => item.id !== action.payload.id)

        ctx.setState({
            ...state,
            simplePois: newPois
        })
    }

    @Action(SetPrivileges)
    setPrivileges(ctx: StateContext<Session>, action: SetPrivileges) {
        const state = ctx.getState()

        ctx.setState({
            ...state,
            privileges: action.payload,
            permissions: this.privilegeToPermission(action.payload, state.profile.role)
        })
    }

    @Action(SetUnreadNum)
    setUnreadNum(ctx: StateContext<Session>, action: SetUnreadNum) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            unreadNum: action.payload
        })
    }

    @Action(AddUnreadNum)
    addUnreadNum(ctx: StateContext<Session>, action: AddUnreadNum) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            unreadNum: state.unreadNum + action.payload
        })
    }

    @Action(ShowMapViewPanel)
    showMapViewPanel(ctx: StateContext<Session>, action: ShowMapViewPanel) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            showMapViewPanel: action.payload
        })
    }

    @Action(SetCountries)
    setCountries(ctx: StateContext<Session>, action: SetCountries) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            countries: action.payload
        })
    }

    @Action(SetProvinces)
    setProvinces(ctx: StateContext<Session>, action: SetProvinces) {
        const state = ctx.getState()
        const provincesObj = Object.assign({}, state.provincesObj)
        provincesObj[action.countryId] = action.payload
        ctx.setState({
            ...state,
            provincesObj
        })
    }

    @Action(SetTimeZones)
    setTimeZones(ctx: StateContext<Session>, action: SetTimeZones) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            timezones: action.payload
        })
    }

    @Action(SetSelectedTrackerId)
    setSelectedTrackerId(ctx: StateContext<Session>, action: SetSelectedTrackerId) {
        const state = ctx.getState()
        sessionStorage.setItem(StorageKeys.activedTracker, action.payload)
        ctx.setState({
            ...state,
            selectedTrackerId: action.payload
        })
    }

    @Action(SetSelectedPOIId)
    setSelectedPOIId(ctx: StateContext<Session>, action: SetSelectedPOIId) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            selectedPOIId: action.payload
        })
    }

    @Action(SetSelectedGeoFenceId)
    setSelectedGeoFenceId(ctx: StateContext<Session>, action: SetSelectedGeoFenceId) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            selectedGeoFenceId: action.payload
        })
    }

    @Action(SetServiceType)
    setServiceType(ctx: StateContext<Session>, action: SetServiceType) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            serviceType: action.payload
        })
    }

    @Action(ResetState)
    resetState(ctx: StateContext<Session>) {
        ctx.setState({
            ...initSessionState
        })
    }

    @Action(SetStoreStates)
    setStoreStates(ctx: StateContext<Session>, action: SetStoreStates) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            reportStates: action.payload
        })
    }

    @Action(SetSosTracker)
    setSosTracker(ctx: StateContext<Session>, action: SetSosTracker) {
        const state = ctx.getState()
        const stateTrackers = cloneDeep(state.trackers)
        const sosTrackerIds = action.payload
        stateTrackers.forEach(tracker => {
            if (sosTrackerIds.includes(tracker.tid)) {
                tracker.sos = 1
            }
        })
        ctx.setState({
            ...state,
            trackers: stateTrackers
        })
    }

    @Action(ShowMapViewPoi)
    showMapViewPoi(ctx: StateContext<Session>, action: ShowMapViewPoi) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            showMapViewPoi: action.payload
        })
    }

    @Action(ToggleShowMapPoi)
    toggleShowMapPoi(ctx: StateContext<Session>, action: ToggleShowMapPoi) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            showMapPoi: action.payload
        })
    }

    @Action(SetSimpleRegions)
    setSimpleRegions(ctx: StateContext<Session>, action: SetSimpleRegions) {
        const state = ctx.getState()
        const regions = action.payload
        const newRegions = regions.map(region => {
            const marker = this.createFenceMarker(region)
            region.latitude = marker.lat
            region.longitude = marker.lng

            return region
        })
        ctx.setState({
            ...state,
            simpleRegions: newRegions
        })
    }

    @Action(SetSimplePois)
    setSimplePois(ctx: StateContext<Session>, action: SetSimplePois) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            simplePois: action.payload
        })
    }

    @Action(ShowMapViewFences)
    showMapViewFences(ctx: StateContext<Session>, action: ShowMapViewFences) {
        const state = ctx.getState()
        ctx.setState({
            ...state,
            showFences: action.payload
        })
    }

    @Action(UpdateSimpleFence)
    updateSimpleFence(ctx: StateContext<Session>, action: UpdateSimpleFence) {
        const state = ctx.getState()
        const fence = this.fenceToSimpleFence(action.payload)
        const fences = state.simpleRegions
        const index = fences.findIndex(item => item.id === fence.id)
        if (index < 0) {
            return
        }
        const distFence = fences[index]
        const newFence = {...distFence, ...fence}
        const newFences = [...fences]
        newFences[ index ] = newFence
        ctx.setState({
            ...state,
            simpleRegions: newFences
        })
    }

    @Action(AddSimpleFence)
    addSimpleFence(ctx: StateContext<Session>, action: AddSimpleFence) {
        const state = ctx.getState()
        const fence = this.fenceToSimpleFence(action.payload)
        const fences = state.simpleRegions
        ctx.setState({
            ...state,
            simpleRegions: [...fences, fence]
        })
    }

    @Action(RemoveSimpleFence)
    removeSimpleFence(ctx: StateContext<Session>, action: RemoveSimpleFence) {
        const state = ctx.getState()
        const fenceId = action.payload

        const newFences = state.simpleRegions.filter(item => item.id !== fenceId)
        ctx.setState({
            ...state,
            simpleRegions: newFences
        })
    }

    @Action(SetIsOpenModifyShape)
    setIsOpenModifyShape(ctx: StateContext<Session>, action: SetIsOpenModifyShape) {
        ctx.patchState({
            showModifyShape: action.payload
        })
    }

    private createFenceMarker(fence) {
        if (fence.circle) {
            return {lat: fence.circle.latitude, lng: fence.circle.longitude}
        } else if (fence.polygon) {
            return  this.getPolygonMarker(fence.polygon)
        }
    }

    private getPolygonMarker(polygon) {
        const lats = polygon.map(item => item.latitude)
        const lngs = polygon.map(item => item.longitude)
        const lat = lats.reduce((prev, curr) => prev + curr, 0) / lats.length
        const lng = lngs.reduce((prev, curr) => prev + curr, 0) / lngs.length

        return { lat, lng }
    }

    private privilegeToPermission(privileges, profileRole): string[] {
        const perArr = []
        Object.entries(privileges).forEach(([key, value]) => {
            if (value) {
                perArr.push(permissionsDict[key])
            }
        })

        // 0=user, 1=admin, 2=dept admin, 3=driver
        // 1 超级管理员 2 部门管理员 3 司机
        switch (profileRole) {
            case 0:
                break
            case 1:
                perArr.push(rolePermissions['show_users_manager'], rolePermissions['show_driver_score'])
                break
            case 2:
                perArr.push(
                  rolePermissions['show_driver_management'],
                  rolePermissions['show_access_control'],
                  rolePermissions['show_driver_score'])
                break
            case 3:
                perArr.push(rolePermissions['show_driver_score'])
                break
            default:
                break
        }

        return perArr
    }

    private poiToSimplePoi(poi) {
        const {
            id,
            name,
            address,
            latitude,
            longitude,
            radius,
            is_shared,
            avatar_path,
            marker_path,
        } = poi

        return {
            id,
            name,
            address,
            latitude,
            longitude,
            radius,
            is_shared,
            avatar_path,
            marker_path,
        }
    }

    private fenceToSimpleFence(fence) {
        const marker = this.createFenceMarker(fence)

        return {
           ...fence,
            latitude: marker.lat, longitude: marker.lng
        }
    }
}
