import { Component, OnInit } from '@angular/core'
import * as Bowser from 'bowser'

@Component({
    selector: 'browser-update',
    templateUrl: 'browser-update.component.html',
    styleUrls: ['browser-update.component.less']
})
export class BrowserUpdateComponent implements OnInit {
    showTip = false
    browserEngine
    browserName

    constructor() {
    }

    ngOnInit() {
        const browser = Bowser.getParser(window.navigator.userAgent)
        this.browserEngine = browser.getEngine().name
        this.browserName = browser.getBrowser().name
        const ignoreBrowserTime = localStorage.getItem('ignoreUpdateBrowserTime')
        const nowTime = new Date().getTime()
        if (this.browserEngine === 'Blink' || (nowTime - Number(ignoreBrowserTime) < 7 * 24 * 60 * 60 * 1000)) {
            this.showTip = false
        } else {
            this.showTip = true
        }
    }

    linkToChrome() {
        window.open('https://www.google.com/chrome', 'blank')
    }

    linkToEdge() {
        window.open('https://www.microsoft.com/en-us/edge', 'blank')
    }

    ignore() {
        const nowTimeString = new Date().getTime().toString()
        localStorage.setItem('ignoreUpdateBrowserTime', nowTimeString)
        this.showTip = false
    }
}
